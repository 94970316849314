import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EMPTY, Observable, map, of } from 'rxjs';
import { SysDisciplineDto } from '../models/system/sys-discipline.model';
import {
  DisciplineDto,
  DisciplinePricingLevelDto,
} from '../models/discipline.model';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import {
  createInsertBody,
  createPatchBody,
  createUpdateBody,
} from '../forms/forms.utils';
import {
  ServiceByClinicActivationParameters,
  ServiceDropDownListParameters,
  ServiceParameters,
} from '../models/parameters/serviceParameters';
import {
  ClinicServiceAbbrDto,
  ServiceDropDownListItemDto,
  ServiceDto,
} from '../models/services/service.model';
import { CoProductDto } from '../models/products/co-product.model';
import { CoProductsParameters } from '../models/parameters/productParameters';
import { CoProductCategoryDto } from '../models/products/co-product-category.model';
import {
  CasePhaseTreatementProtocolTemplateDto,
  ClientCasesForProtocolTemplateCoManagementDto,
  DistributedCasePhaseTreatementProtocolDropDownListItemDto,
  DistributeProtocolsParameters,
} from '../models/cases/protocols.model';
import {
  ActiveDropDownListItemDto,
  ActiveEntitiesDto,
  DropDownListItemDto,
  PractitionerDropDownListItemDto,
  RevenueDivisionDropdownListItemDto,
  SortedDropDownListItemDto,
} from '../models/menus/drop-down-list-item.model';
import {
  DistributeMembershipPlanResults,
  DistributeProductParameters,
  DistributedResults,
} from '../models/parameters/distributeProductParameters';
import { DistributeServiceParameters } from '../models/parameters/distributeServiceParameters';
import {
  ServiceCategoryDropDownWithLevelsDto,
  ServiceCategoryDto,
} from '../models/services/service-category.model';
import { ServiceConfirmationMessageDto } from '../models/services/service-confirmation-message.model';
import { AddatechNonceDto } from '../models/clinic/addatech-nonce.model';
import {
  ClinicDto,
  ClinicGroupFranchiseDto,
  ClinicGroupFranchiseOnlineBookingSetupDto,
  CoClinicWithPractitionerLicenseCountValidationDto,
  DistributedClinicDto,
  RegionDto,
  SubRegionDto,
} from '../models/franchise/franchise.model';
import {
  CaseProtocolTemplateForManagementCoSearchParameters,
  CaseTemplateForManagementCoSearchParameters,
  CoCaseTemplateFilterParameters,
} from '../models/parameters/case-templates.parameters';
import { ToQueryString } from './params-to-query.service';
import { CoProductDistributedProductDto } from '../models/products/co-product-distributed-product.model';
import { ServiceDistributedServiceDto } from '../models/services/service-distributed-service.model';
import { ProductDropDownListItemDto } from '../models/products/product-dropdownlist.model';
import {
  CoClinicCopyParameters,
  CoEntitiesParameters,
  CoParameters,
  GreatPlainsMontlyReportParameters,
} from '../models/parameters/co-parameters';
import {
  CoAbbreviatedStaffUserDto,
  CoUserDto,
  EntityPractitionerClinicAdminDto,
  EntityUserFullNameDto,
  StaffUserDto,
  UserDropDownListDto,
} from '../models/users/user';
import { CaseTemplateForCoManagementDto } from '../models/cases/case-template-for-management.model';
import { ProtocolTemplatesForServiceManagementDto } from '../models/cases/protocol-templates-for-service-management';
import { Identifier } from '../models/storage.models';
import { NoteTemplateDto } from '../models/notes/note-template.model';
import { ServiceRateChangeSetupCreationDto } from '../models/services/service-rate-change-setup.model';
import {
  SecurityRoleByClinicDto,
  SecurityRoleDropdownListItemDto,
  SecurityRoleDto,
} from '../models/users/security-role';
import { SysApplications } from '../models/Enums/sys-applications';
import { CaseIntegrationTypeDropDownListItemDto } from '../models/menus/case-integation-type.model';
import {
  ClientCaseDto,
  ClientCaseFunderDto,
  ClientCasePhaseDto,
  ClientCasePhaseReorderDto,
  DistributeCaseTemplatesParameters,
  DistributedCaseTemplateDropDownListItemDto,
} from '../models/cases/case-templates.model';
import { CoUserSecurityRoleParameters } from '../models/parameters/co-user-security-role-parameters';
import {
  CoAbbreviatedEntityDto,
  CoAdminPractitionerCreationDto,
  CompletePractitionerDto,
  CoPractitionerDto,
  PractitionerDto,
} from '../models/practitioners/practitioner.models';
import {
  AbbreviatedEntityDto,
  AbbreviatedEntityPractitionerClinicAdminDto,
} from '../models/entities/entity';
import {
  ClinicAdministratorDto,
  CoClinicAdministratorCreationDto,
  CoClinicAdministratorDto,
  CoClinicAdministratorUpdateDto,
} from '../models/admin-staff/clinic-administrator.model';
import { SysDaySheetStyleDto } from '../models/system/sys-daysheet-style';
import {
  AcctTaxRateSetupDto,
  AcctTaxSetupDto,
} from '../models/accounting/tax.mode';
import { AcctGlaccountDto } from '../models/accounting/acctglaccount.model';
import { AcctPractitionerCompensationSetupTaxDto } from '../models/accounting/acct-practitioner-compensation-setup-taxes.model';
import { AcctPractitionerCompensationSetupTaxRateDto } from '../models/accounting/acct-practitioner-compensation-setup-tax-rate.model';
import { AcctPractitionerCompensationSetupCategoryDto } from '../models/accounting/acct-practitioner-compensation-setup-category.model';
import { AcctPractitionerCompensationSetupDropDownListDto } from '../models/accounting/acct-practitioner-compensation-setup-drop-down-list.model';
import { AcctPractitionerCompensationSetupCategoryServiceDto } from '../models/accounting/acct-practitioner-compensation-category-service.model';
import { AcctPractitionerCompensationSetupCategoryProductDto } from '../models/accounting/acct-practitioner-compensation-category-product.model';
import { ProcessQueueCategoryDto } from '../models/process-queues/process-queue-category.model';
import { ProcessQueueDto } from '../models/process-queues/process-queue.model';
import {
  CoAbbreviatedMembershipPlanByClinicDto,
  MembershipPlanDto,
} from '../models/memberships/membership-plan';
import { MembershipPlanEligibleServiceDto } from '../models/memberships/membership-plan-eligible-service';
import {
  CoMembershipPlanEligibleProductOrServiceBatchCreationDto,
  MembershipPlanEligibleProductDto,
} from '../models/memberships/membership-plan-eligible-product';
import { ClinicFranchiseSupportedNotificationTypeDto } from '../models/clinic-franchise-supported-notification-type.model';
import { SysAppointmentNotificationTypeDto } from '../models/sys-appointment-notification-type.model';
import { DistributeMembershipPlanParameters } from '../models/parameters/distribute-membership-parameters';
import { CoFranchiseeCompensationRevenuRangeDto } from '../models/central-office/co-franchisee-compensation-revenu-range.model';
import {
  CoFranchiseeCompensationHistoryCreationDto,
  FranchiseeCompensationHistoryDto,
} from '../models/franchise/franchisee-compensation-history.model';
import { CoFranchiseeCompensationProductCategoryDto } from '../models/central-office/co-franchisee-compensation-product-category.model';
import { CoFranchiseeCompensationProductDto } from '../models/central-office/co-franchisee-compensation-product.model';
import { OrderParameters } from '../models/parameters/order-parameters';
import { EducationalContentInvitationDto } from '../models/educational-content/models/educational-content-invitation.model';
import { EducationalContentDto } from '../models/educational-content/models/educational-content.model';
import { EducationalContentCategoryDto } from '../models/educational-content/models/educational-content-category.model';
import { createPutFormData } from '../utils/forms/forms.utils';
import {
  CoFranchiseeCompensationProductCategoriesDisciplineCreationDto,
  CoFranchiseeCompensationProductCategoriesDisciplineDto,
} from '../models/central-office/co-franchisee-compensation-product-categories-discipline.model';
import {
  CoFranchiseeCompensationProductCategoriesProductCategoryCreationDto,
  CoFranchiseeCompensationProductCategoriesProductCategoryDto,
} from '../models/central-office/co-franchisee-compensation-product-categories-product-category.model';
import { ReportDto } from '../models/reports/report.model';
import { ChartNoteTemplateCategoryDto } from '../models/chartnote-categories/chart-note-template-category.model';
import { ChartNoteTemplateSubCategoryDto } from '../models/chartnote-categories/chart-note-template-sub-category.model';
import { ClinicProductAbbrDto, ProductDto } from '../models/products/product';
import { FunderDto } from '../models/funders.model';
import { SysRequiredTableFieldCandidateDto } from '../models/system/sys-required-table-field-candidate.model';
import { CoFranchiseeCompensationSetupDto } from '../models/central-office/co-franchisee-compensation-setup.model';
import { CityDto } from '../models/reference-tables/city.model';
import { SysStateDto } from '../models/system/sys-state.model';
import { CustomFieldDto } from '../models/custom-fields/custom-field.model';
import { CustomFieldComboValueDto } from '../models/custom-fields/custom-field-combo-value.model';
import { SysCustomFieldDto } from '../models/system/sys-custom-fields.model';
import {
  DefaultOutReasonDto,
  OutReasonDto,
} from '../models/pick-list/out-reasons.model';
import { EquipmentDto } from '../models/pick-list/equipment.model';
import { DefaultRoomDto, RoomDto } from '../models/pick-list/room.model';
import {
  DefaultRoomReservationReasonDto,
  RoomReservationReasonDto,
} from '../models/pick-list/room-reservation-reason.model';
import { InterestGroupDto } from '../models/references/interest-group';
import { InterestDto } from '../models/references/interest.model';
import {
  ChartNoteTemplateFormDto,
  ChartNoteTemplateFormRequestDto,
  UnassociatedChartNoteTemplateFormDto,
} from '../models/chartnote-categories/chart-note-template-form.model';
import {
  BillableItemDto,
  BillingItemRequestDto,
  CaseIntegrationType,
  CaseIntegrationTypeItem,
  ChartNoteTypeDto,
  SysChartNoteType,
  TemplateFormClinicDto,
  TemplateFormClinicFunderDto,
  TemplateFormClinicPractitionerDto,
  TemplateFormEntityDto,
} from '../models/chartnote-categories/chart-note-type.model';
import {
  InjuryDto,
  InjuryFamilyDto,
  SysICDCodeDto,
} from '../models/pick-list/injury.models';
import { APIRoutesConfig } from '../../api-routes.config';
import { BaseHttpService } from './base-http.service';
import { SysGroupForAnalyticCategoryDto } from '../models/system/sys-group-for-analytic-category.model';
import { GroupForAnalyticDto } from '../models/service-category.model';
import { ClinicGroupFranchiseProductCategoryDto } from '../models/clinicGroupFranchiseProductCategory.model';
import {
  DefaultReferringGroup,
  ReferringGroup,
} from '../models/references/referring-group.model';
import { ReferringCategory } from '../models/references/referring-category.model';
import { ControlCenterTabDto } from '../models/controlcenter/control-center-tab';
import {
  ClinicControlCenterColorDto,
  COClinicControlCenterColorCreationDto,
} from '../models/controlcenter/clinic-control-center-color.model';
import { COBatchSaveResponse } from '../models/central-office/so-batch-responce.model';
import { SysReportStyleDto } from '../models/system/sys-report-style.model';
import {
  ClinicApplicationSetupDto,
  CoClinicApplicationSetupSaveDto,
} from '../models/central-office/co-clinic-application-setup.model';
import { CaseDropDownListItemDto } from '../models/cases/case-drop-down-list-item.model';
import { ClinicHolidayDto } from '../models/clinic/clinic-holiday.model';
import { ClinicSetupDto } from '../models/clinic/clinic-setup.model';
import { SysMeasurementSystemDto } from '../models/system/sys-measurement-system.model';
import { ClinicOfficeHourDto } from '../models/clinic/clinic-office-hour.model';
import { PractitionerChartNoteSharingTeamDto } from '../models/practitioners/practitioner-chart-note-sharing-team.model';
import { AcctPostingScheduleDto } from '../models/accounting/acct-posting-schedule.model';
import { AcctPractitionerCompensationForHolidayDto } from '../models/practitioner-compensation/acct-practitioner-compensation-for-holiday.model';
import { AcctPractitionerCompensationSetupByClinicDto } from '../models/practitioner-compensation/acct-practitioner-compensation-setup-by-clinic.model';
import { StaffTeamDto } from '../models/administration-teams/staff-team.model';
import { CoStaffTeamMemberByClinicDto } from '../models/administration-teams/staff-team-member-by-clinic.model';
import { SysBillingPostingOptionDto } from '../models/billing/billing-option.model';
import { AcctClinicSetupDto } from '../models/clinic/acct-clinic.model';
import { SysAppointmentCancellationBillingOptionDto } from '../models/sys-appointment-cancellation-billing-option.model';
import { AppointmentCancellationRateToBillExceptionDto } from '../models/accounting/appointment-cancellation-rate-to-bill-exception.model';
import { InvoicePrefixDto } from '../models/accounting/invoice-prefix.model';
import { SysInvoiceStyleDto } from '../models/sys-invoice-style.model';
import { AcctInvoiceStyleDto } from '../models/accounting/acct-invoice-style';
import { SysChartNoteHeaderFieldDto } from '../models/system/sys-chart-note-header-field.model';
import { SysDocumentTypeDto } from '../models/system/sys-document-type.model';
import {
  ClinicChartNoteHeaderDocumentTypeSetupDto,
  ClinicChartNoteHeaderSetupDto,
} from '../models/chart-note-headers/chart-note-header-field.model';
import { RevenueDivisionDto } from '../models/revenue-division.model';
import { ClinicOnlineBookingSetupDto } from '../models/clinic-online-booking-setup.model';
import { SecuredMessageCategoryDto } from '../models/secured-message-category.model';
import { SecuredMessageDefaultRecipientDto } from '../models/secured-message-default-recipient.model';
import { CoAcctSetupDto } from '../models/clinic/co-account-setup.model';
import { CoSysApplicationModuleByLevelDto } from '../models/clinic/clinic.model';
import { AcctPractitionerCompensationSummaryDto } from '../models/accounting/acct-practitioner-compensation-summary.model';
import {
  AcctPractitionerCompensationImportfromPackageDto,
  AcctPractitionerCompensationSetupExceptionCreation,
  AcctPractitionerCompensationSetupExceptionDto,
} from '../models/accounting/acct-practitioner-compensation-setup-exception.model';
import {
  AcctPractitionerCompensationSetupOutBatch,
  AcctPractitionerCompensationSetupOutDto,
} from '../models/accounting/acct-practitioner-compensation-setup-out.model';
import { AccPractitionerPaymentModeExceptionsDto } from '../models/accounting/acct-practitioner-compensation-based-on-exceptions.model';
import { SysPractitionerPaymentModeDto } from '../models/sys-practitioner-payment-mode.model';
import { AcctPractitionerCompensationForFixedFeeDto } from '../models/accounting/acct-practitioner-compensation-for-fixed-fee.model';
import {
  AcctPractitionerCompensationSetupCategoryMemberships,
  AcctPractitionerCompensationSetupCategoryMembershipsBatch,
  AcctPractitionerCompensationSetupDto,
  AcctPractitionerCompensationSetupGeneral,
} from '../models/accounting/acct-practitioner-compensation-setup.model';
import { SysApplicationModuleEnum } from '../models/sys-application-module.enum';
import {
  ChartNoteTemplateSmartDocDto,
  SysChartNoteSmartDocCategoryDto,
  SysChartNoteSmartDocTemplateDto,
} from '../models/chart-note-template-smart-doc.model';
import { FunderDropdownItemModel } from '../models/funder-dropdown-item.model';
import { PractitionerDropdownItem } from '../models/practitioners/practitioner-dropdown-item.model';
import { ChartNoteSmartDocBillableItemsOnSignedOffDto } from '../models/chart-note-smart-doc-billable-items-on-signed-off.model';
import { ChartNoteTemplateSmartDocEntityMappingDto } from '../models/chart-note-template-smart-doc-entity-mapping.model';
import {
  COClinicWithGpPostingDetailsDto,
  CoPostingDateAndClinicsDto,
} from '../models/central-office/co-posting-clinic.model';

@Injectable({ providedIn: 'root' })
export class ClinicHttpService extends BaseHttpService {
  constructor(private http: HttpClient, private helper: ToQueryString) {
    super();
  }

  getClinicGroupFranchise(
    franchiseId: number | string
  ): Observable<ClinicGroupFranchiseDto | null> {
    const url = `${this.coApiUrl}clinicgroupfranchises/${franchiseId}`;
    return this.http
      .get<ClinicGroupFranchiseDto>(url, { observe: 'response' })
      .pipe(map((response) => response.body));
  }
  getNonceIdForHelpSystemSession(): Observable<string> {
    const url = `${this.coApiUrl}nonces/singlesignon`;
    return this.http.post<string>(url, null);
  }

  getUnassociatedDisciplines(): Observable<DisciplineDto[]> {
    const url = `${this.coApiUrl}disciplines/unassociated`;
    return this.http.get<DisciplineDto[]>(url);
  }

  getFranchiseModules(): Observable<CoSysApplicationModuleByLevelDto> {
    const url = `${this.coApiUrl}centraloffices/clinics/modules`;
    return this.http.get<CoSysApplicationModuleByLevelDto>(url);
  }

  // go to INITIAL DATA FETCH SERVICE.
  // getSysDisciplines(): Observable<SysDisciplineDto[]> {
  //   const url = `${APIRoutesConfig.coApiUrl}sysdisciplines`;
  //   return this.http.get<SysDisciplineDto[]>(url);
  // }
  getSysDisciplinesByLevel(): Observable<SysDisciplineDto[]> {
    const url = `${APIRoutesConfig.coApiUrl}sysdisciplines/bylevel`;
    return this.http.get<SysDisciplineDto[]>(url);
  }
  getDisciplines(): Observable<DisciplineDto[]> {
    const url = `${this.coApiUrl}centraloffices/discipline`;
    return this.http.get<DisciplineDto[]>(url);
  }

  insertDiscipline(form: UntypedFormGroup): Observable<DisciplineDto> {
    const url = `${this.coApiUrl}centraloffices/discipline`;
    return this.http.post<DisciplineDto>(url, createInsertBody(form));
  }

  patchDiscipline(form: UntypedFormGroup): Observable<DisciplineDto> {
    const url = `${this.coApiUrl}centraloffices/discipline/${
      form.get('disciplineId')?.value
    }`;
    return this.http.post<DisciplineDto>(url, createUpdateBody(form));
  }

  deleteDiscipline(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/discipline/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  deleteDisciplineFromClinic(
    clinicId: number,
    disciplineId: number
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/discipline/${disciplineId}/deletefromclinic/${clinicId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  deleteService(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/services/co/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  //toQueryString ????param: ServiceParameters
  getServices(param: ServiceParameters): Observable<ServiceDto[]> {
    const url = `${this.coApiUrl}centraloffices/services/co`;
    return this.http.get<ServiceDto[]>(url);
  }
  getActiveServices(clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}services/?isActive=true`;
    return this.http.get<ServiceDto[]>(url, { headers });
  }
  getClinicServices(clinicId: number): Observable<ClinicServiceAbbrDto[]> {
    const url = `${this.coApiUrl}centraloffices/services/clinic/${clinicId}`;
    return this.http.get<ClinicServiceAbbrDto[]>(url);
  }
  getClinicProducts(clinicId: number): Observable<ClinicProductAbbrDto[]> {
    const url = `${this.coApiUrl}centraloffices/products/clinic/${clinicId}`;
    return this.http.get<ClinicProductAbbrDto[]>(url);
  }
  getProductsByClinic(clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}products`;
    return this.http.get<ProductDto[]>(url, { headers });
  }
  getClinicFunders(
    clinicId: number,
    showDeleted: boolean
  ): Observable<FunderDto[]> {
    const url = `${this.coApiUrl}centraloffices/funders/byclinic?id=${clinicId}&includeDeleted=${showDeleted}`;
    return this.http.get<FunderDto[]>(url);
  }
  getClinicPractitioners(
    clinicId: number,
    includeDeleted = false
  ): Observable<CoAbbreviatedEntityDto[]> {
    const url = `${this.coApiUrl}centraloffices/practitioners/abbreviated/search?id=${clinicId}&includeDeleted=${includeDeleted}`;
    return this.http.get<CoAbbreviatedEntityDto[]>(url);
  }
  unlinkFunderFromClinic(clinicId: number, entityId: number) {
    const url = `${this.coApiUrl}centraloffices/entities/${clinicId}/${entityId}/funders/associations`;
    return this.http.delete(url);
  }
  copyClinicItemsToAnotherClinic(parameters: {
    toClinicId: number;
    fromClinicId: number;
    copyClinicItemType: number;
  }) {
    const url = `${this.coApiUrl}centraloffices/clinics/copyitems`;
    return this.http.post(url, parameters);
  }
  getUnassociatedFunders(clinicId: number) {
    const url = `${this.coApiUrl}centraloffices/funders/unassociated/${clinicId}`;
    return this.http.get<FunderDto[]>(url);
  }
  linkFundersToClinic(clinicId: number, funderIds: number[]) {
    const url = `${this.coApiUrl}centraloffices/entities/${clinicId}/funders/associate`;
    return this.http.post(url, funderIds);
  }
  distributeDisciplines({
    clinicIds,
    items,
  }: {
    clinicIds: number[];
    items: number[];
  }): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/discipline/distribute`;
    return this.http.post<DisciplineDto>(url, {
      ClinicIds: clinicIds.map((clinicId) => ({ ClinicId: clinicId })),
      Items: items.map((item) => ({ DisciplineId: item })),
    });
  }
  deleteDisciplinePricingLevels(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/disciplines/pricinglevels/sysDiscipline/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  addDisciplinePricingLevels(
    sysDisciplineId: number,
    pricinLevels: DisciplinePricingLevelDto[]
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/disciplines/pricinglevels/sysDiscipline/${sysDisciplineId}`;
    return this.http.post(url, pricinLevels);
  }
  updateDisciplinePricingLevels(
    sysDisciplineId: number,
    pricinLevels: DisciplinePricingLevelDto[]
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/disciplines/pricinglevels/sysDiscipline/${sysDisciplineId}`;
    return this.http.put(url, pricinLevels);
  }
  GetServiceCategories(): Observable<ServiceCategoryDto[]> {
    const url = `${this.coApiUrl}centraloffices/servicecategories`;
    return this.http.get<ServiceCategoryDto[]>(url);
  }
  GetCoServiceCategoriesDropDownListItem(): Observable<
    ServiceCategoryDropDownWithLevelsDto[]
  > {
    const url = `${this.coApiUrl}centraloffices/servicecategories/dropdownlist`;
    return this.http.get<ServiceCategoryDropDownWithLevelsDto[]>(url);
  }
  //#region  products
  getProductCategories(): Observable<CoProductCategoryDto[]> {
    const url = `${this.coApiUrl}centraloffices/productcategories`;
    return this.http.get<CoProductCategoryDto[]>(url);
  }
  getProductCategoriesDropDownList(): Observable<DropDownListItemDto[]> {
    const url = `${this.coApiUrl}centraloffices/productcategories/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }
  getClinicFranchiseProductCategories() {
    const url = `${this.coApiUrl}productcategories/clinicgroupfranchise`;
    return this.http.get<ClinicGroupFranchiseProductCategoryDto[]>(url);
  }
  getProducts(param: CoProductsParameters): Observable<CoProductDto[]> {
    let params = new HttpParams()
      .append('isGiftCertificate', param.isGiftCertificate.toString())
      .append('isMembership', param.isMembership.toString())
      .append('isPrepayment', param.isPrepayment.toString())
      .append('isTip', param.isTip.toString());
    const url = `${this.coApiUrl}centraloffices/products/co`;
    return this.http.get<CoProductDto[]>(url, { params });
  }
  GetCoProductsByTypeDropDownListItem(
    param: CoProductsParameters
  ): Observable<ProductDropDownListItemDto[]> {
    let params = new HttpParams()
      .append('isGiftCertificate', param.isGiftCertificate.toString())
      .append('isMembership', param.isMembership.toString())
      .append('isPrepayment', param.isPrepayment.toString())
      .append('isTip', param.isTip.toString());
    const url = `${this.coApiUrl}centraloffices/products/bytype/dropdownlist`;
    return this.http.get<ProductDropDownListItemDto[]>(url, { params });
  }
  deleteProduct(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/products/co/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  addCoProduct(form: UntypedFormGroup): Observable<CoProductDto> {
    const url = `${this.coApiUrl}centraloffices/products/co`;
    return this.http.post<CoProductDto>(url, createInsertBody(form));
  }
  updateCoProduct(form: UntypedFormGroup): Observable<CoProductDto> {
    let id = form.get('productId')?.value;
    const url = `${this.coApiUrl}centraloffices/products/co/${id}`;
    return this.http.put<CoProductDto>(url, createUpdateBody(form));
  }
  distributeProducts(
    params: DistributeProductParameters
  ): Observable<DistributedResults[]> {
    const url = `${this.coApiUrl}centraloffices/products/distribute`;
    return this.http.post<DistributedResults[]>(url, params);
  }
  getProductDistributedClinics(
    productId: number
  ): Observable<CoProductDistributedProductDto[]> {
    const url = `${this.coApiUrl}centraloffices/products/distributed/${productId}`;
    return this.http.get<CoProductDistributedProductDto[]>(url);
  }
  getDistributedServices(
    serviceId: number
  ): Observable<ServiceDistributedServiceDto[]> {
    const url = `${this.coApiUrl}centraloffices/services/distributed/${serviceId}`;
    return this.http.get<ServiceDistributedServiceDto[]>(url);
  }

  getActiveServicesByClinic(clinicId: number, serviceIds?: number[]) {
    let tailParams = '';
    if (serviceIds && serviceIds.length > 0) {
      serviceIds.forEach((id) => {
        tailParams = `${tailParams}&serviceIds=${id}`;
      });
    }
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}services/?isActive=true${tailParams}`;
    return this.http.get<ServiceDto[]>(url, { headers });
  }

  deleteProductFromClinic(
    clinicId: number,
    productId: number
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/products/${productId}/deletefromclinic/${clinicId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  deleteServiceFromClinic(
    clinicId: number,
    serviceId: number
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/services/${serviceId}/deletefromclinic/${clinicId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  mergeProducts(form: UntypedFormGroup): any {
    const url = `${this.coApiUrl}products/merge`;
    return this.http.post(url, createInsertBody(form));
  }
  changeProductsRetailPrice(productId: number, form: UntypedFormGroup): any {
    const url = `${this.coApiUrl}centraloffices/products/${productId}/changeretailprice`;
    return this.http.post(url, createInsertBody(form));
  }
  changeServiceRate(serviceId: number, form: UntypedFormGroup): any {
    const url = `${this.coApiUrl}centraloffices/services/${serviceId}/changerate`;
    return this.http.post(url, createInsertBody(form));
  }
  changeServiceRates(
    clinicId: number,
    serviceId: number,
    model: ServiceRateChangeSetupCreationDto
  ): any {
    const url = `${this.coApiUrl}centraloffices/servicerates/setups/clinic/${clinicId}/service/${serviceId}`;
    return this.http.post(url, model);
  }
  changeActivationByClinic(
    serviceId: number,
    items: ServiceByClinicActivationParameters[]
  ): any {
    const url = `${this.coApiUrl}centraloffices/services/${serviceId}/clinics/activation`;
    return this.http.post(url, items);
  }
  //#endregion products
  distributeServices(
    params: DistributeServiceParameters
  ): Observable<DistributedResults[]> {
    const url = `${this.coApiUrl}centraloffices/services/distribute`;
    return this.http.post<DistributedResults[]>(url, params);
  }
  getServiceConfirmationMessages(): Observable<
    ServiceConfirmationMessageDto[]
  > {
    const url = `${this.coApiUrl}serviceconfirmationmessages`;
    return this.http.get<ServiceConfirmationMessageDto[]>(url);
  }
  getNonceIdForAddatechHelpSystemSession(): Observable<AddatechNonceDto> {
    const url = `${this.coApiUrl}nonces/singlesignonaddatech`;
    return this.http.post<AddatechNonceDto>(url, null);
  }

  getDisciplineDistributedClinics(
    id: number
  ): Observable<DistributedClinicDto[]> {
    const url = `${this.coApiUrl}centraloffices/discipline/distributed/${id}`;
    return this.http.get<DistributedClinicDto[]>(url);
  }

  getAcctPractitionerCompensationRemunerationModesDropDownList() {
    const url = `${this.coApiUrl}sysremunerationmodes/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }

  getOutReasonsDropDown() {
    const url = `${this.coApiUrl}outreasons/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }

  getAppointmentDeliveryMethodsDropDownListItem(): Observable<
    DropDownListItemDto[]
  > {
    const url = `${this.coApiUrl}sysappointmentdeliverymethods/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }
  getCasePlanTypesDropDownListItem(): Observable<DropDownListItemDto[]> {
    const url = `${this.coApiUrl}syscaseplantypes/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }
  getCaseCoverageTypesDropDownList(): Observable<DropDownListItemDto[]> {
    const url = `${this.coApiUrl}casecoveragetypes/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }
  getCaseContractsDropDownListItem(): Observable<DropDownListItemDto[]> {
    const url = `${this.coApiUrl}caseContracts/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }
  getNoteTemplates(): Observable<NoteTemplateDto[]> {
    const url = `${this.coApiUrl}notetemplates`;
    return this.http.get<NoteTemplateDto[]>(url);
  }
  getCoCasePhaseTreatementProtocolTemplates(
    prm: CoCaseTemplateFilterParameters
  ): Observable<CasePhaseTreatementProtocolTemplateDto[]> {
    const url = `${this.coApiUrl}centraloffices/v2/casephasetreatementprotocoltemplates`;
    return this.http
      .get<CasePhaseTreatementProtocolTemplateDto[]>(
        url,
        this.helper.toQueryStringParams(prm)
      )
      .pipe(map((response) => response.body || []));
  }
  getCasePhaseTreatementProtocolTemplates(
    prm: CoCaseTemplateFilterParameters
  ): Observable<CasePhaseTreatementProtocolTemplateDto[]> {
    const url = `${this.coApiUrl}centraloffices/casephasetreatementprotocoltemplates/co`;
    return this.http
      .get<CasePhaseTreatementProtocolTemplateDto[]>(
        url,
        this.helper.toQueryStringParams(prm)
      )
      .pipe(map((response) => response.body || []));
  }
  getCasePhaseTreatementProtocolTemplate(
    templateId: number,
    clinicId: number
  ): Observable<CasePhaseTreatementProtocolTemplateDto> {
    const url = `${this.coApiUrl}casephasetreatementprotocoltemplates/${templateId}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.get<CasePhaseTreatementProtocolTemplateDto>(url, {
      headers,
    });
  }
  addCasePhaseTreatementProtocolTemplate(
    model: any,
    clinicId: number
  ): Observable<CasePhaseTreatementProtocolTemplateDto> {
    const url = `${this.coApiUrl}casephasetreatementprotocoltemplates`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.post<CasePhaseTreatementProtocolTemplateDto>(url, model, {
      headers,
    });
  }

  updateCasePhaseTreatementProtocolTemplate(
    templateId: number,
    model: any,
    clinicId: number
  ): Observable<CasePhaseTreatementProtocolTemplateDto> {
    const url = `${this.coApiUrl}casephasetreatementprotocoltemplates/${templateId}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.put<CasePhaseTreatementProtocolTemplateDto>(url, model, {
      headers,
    });
  }
  deleteCasePhaseTreatementProtocolTemplateFromClinic(
    templateId: number,
    clinicId: number
  ): any {
    const url = `${this.coApiUrl}casephasetreatementprotocoltemplates/${templateId}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.delete(url, { headers });
  }
  deleteCasePhaseTreatementProtocolTemplateDetail(
    templateId: number,
    detailId: number,
    clinicId: number
  ): any {
    const url = `${this.coApiUrl}casephasetreatementprotocoltemplates/${templateId}/details/${detailId}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.delete(url, { headers });
  }
  getDisciplinePricingLevel(): Observable<DisciplinePricingLevelDto[]> {
    const url = `${this.coApiUrl}disciplines/pricinglevels`;
    return this.http.get<DisciplinePricingLevelDto[]>(url);
  }
  patchService(id: number, form: UntypedFormGroup): Observable<ServiceDto> {
    const url = `${this.coApiUrl}centraloffices/services/co/${id}`;
    return this.http.patch<ServiceDto>(url, createPatchBody(form));
  }
  insertService(form: UntypedFormGroup): Observable<ServiceDto> {
    const url = `${this.coApiUrl}centraloffices/services/co`;
    let model = createInsertBody(form, false);
    return this.http.post<ServiceDto>(url, model);
  }
  updateService(id: number, form: UntypedFormGroup): Observable<ServiceDto> {
    const url = `${this.coApiUrl}centraloffices/services/co/${id}`;
    let model = createUpdateBody(form);
    return this.http.put<ServiceDto>(url, model); //this.http.put<ServiceDto>(url, createUpdateBody(form));
  }
  //#region
  getCoProductCategories(): Observable<CoProductCategoryDto[]> {
    const url = `${this.coApiUrl}centraloffices/productcategories`;
    return this.http.get<CoProductCategoryDto[]>(url);
  }
  deleteCoProductCategory(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/productcategories/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  addCoProductCategory(
    form: UntypedFormGroup
  ): Observable<CoProductCategoryDto> {
    const url = `${this.coApiUrl}centraloffices/productcategories`;
    return this.http.post<CoProductCategoryDto>(url, createInsertBody(form));
  }
  updateCoProductCategory(
    form: UntypedFormGroup
  ): Observable<CoProductCategoryDto> {
    let id = form.get('productCategoryId')?.value;
    const url = `${this.coApiUrl}centraloffices/productcategories/${id}`;
    return this.http.put<CoProductCategoryDto>(url, createUpdateBody(form));
  }
  //#endregion productcategories

  searchCOServices(
    searchValue: ServiceDropDownListParameters
  ): Observable<ServiceDropDownListItemDto[]> {
    const url = `${this.coApiUrl}centraloffices/services/search`;
    const params = this.helper.toQueryStringParams(searchValue);
    const data = { ...params };
    return this.http
      .get<ServiceDropDownListItemDto[]>(url, data)
      .pipe(map((response) => response.body || []));
  }
  searchServicesByClinic(
    searchValue: ServiceDropDownListParameters,
    clinicId: number
  ): Observable<ServiceDropDownListItemDto[]> {
    const url = `${this.coApiUrl}centraloffices/services/${clinicId}/dropdownlist/search`;

    const params = this.helper.toQueryStringParams(searchValue);
    const data = { ...params };
    return this.http
      .get<ServiceDropDownListItemDto[]>(url, data)
      .pipe(map((response) => response.body || []));
  }
  searchCOProducts(pattern: string): Observable<ProductDropDownListItemDto[]> {
    const url = `${this.coApiUrl}centraloffices/products/search?pattern=${pattern}`;

    return this.http
      .get<ProductDropDownListItemDto[]>(url, {
        observe: 'response',
      })
      .pipe(map((response) => response.body || []));
  }
  searchProductsByClinic(
    pattern: string,
    clinicId: number
  ): Observable<ProductDropDownListItemDto[]> {
    const url = `${this.coApiUrl}centraloffices/products/${clinicId}/dropdownlist/search?searchPattern=${pattern}`;
    return this.http
      .get<ProductDropDownListItemDto[]>(url, {
        observe: 'response',
      })
      .pipe(map((response) => response.body || []));
  }
  searchProductsForClinic(
    pattern: string,
    clinicId: number
  ): Observable<ProductDropDownListItemDto[]> {
    const url = `${this.coApiUrl}products/dropdownlist/search?searchpattern=${pattern}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());

    return this.http
      .get<ProductDropDownListItemDto[]>(url, {
        observe: 'response',
        headers: headers,
      })
      .pipe(map((response) => response.body || []));
  }
  getV2ClinicsDropDownForDistributedCoCaseProtocol(
    casePhaseTreatementProtocolTemplateId: number
  ): Observable<DistributedCasePhaseTreatementProtocolDropDownListItemDto[]> {
    const url = `${this.coApiUrl}centraloffices/v2/casephasetreatementprotocoltemplates/${casePhaseTreatementProtocolTemplateId}/byclinic/dropdownlist`;
    return this.http.get<
      DistributedCasePhaseTreatementProtocolDropDownListItemDto[]
    >(url);
  }

  distributeProtocolsByClinicsV2(
    data: DistributeProtocolsParameters
  ): Observable<DistributedResults[]> {
    const url = `${this.coApiUrl}centraloffices/v2/casephasetreatementprotocoltemplates/distribute`;
    return this.http.post<DistributedResults[]>(url, data);
  }
  //#region  users_login
  getCmWebUsersByClinic(
    param: CoParameters
  ): Observable<CoAbbreviatedStaffUserDto[]> {
    let params = new HttpParams()
      .append('id', param.id.toString())
      .append('includeDeleted', param.includeDeleted.toString());
    if (param.pageNumber) {
      params.append('pageNumber', param.pageNumber.toString());
    }
    if (param.pageSize) {
      params.append('pageSize', param.pageSize.toString());
    }
    const url = `${this.coApiUrl}centraloffices/users/search`;
    return this.http.get<CoAbbreviatedStaffUserDto[]>(url, { params });
  }
  changeUserPassword(form: UntypedFormGroup): Observable<any> {
    let userid = form.get('id')?.value;
    if (userid > 0) {
      const url = `${this.coApiUrl}centraloffices/users/${userid}/changepassword/admin`;
      return this.http.put<any>(url, createInsertBody(form));
    }

    return EMPTY;
  }
  getCoUser(userId: number): Observable<CoUserDto> {
    const url = `${this.coApiUrl}centraloffices/users/${userId}`;
    return this.http.get<CoUserDto>(url);
  }
  deleteCoUser(userId: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/users/${userId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  searchPractitionersClinicAdministrators(
    param: CoEntitiesParameters
  ): Observable<EntityPractitionerClinicAdminDto[]> {
    const url = `${this.coApiUrl}centraloffices/entities/practitionersclinicadministrators/search`;

    const params = this.helper.toQueryStringParams(param);
    return this.http
      .get<EntityPractitionerClinicAdminDto[]>(url, params)
      .pipe(map((response) => response.body || []));
  }
  switchTFA(
    isTwoFactorAuthentication: boolean,
    clinicId: string
  ): Observable<any> {
    const bodyArray = [];
    bodyArray.push({
      op: 'replace',
      path: `enableTwoFactorAuthentication`,
      value: isTwoFactorAuthentication,
    });
    const headers = new HttpHeaders().set(Identifier.TYPE_CLINIC_ID, clinicId);
    const url = `${this.coApiUrl}clinicgroupfranchises/myclinicgroupfranchise`;
    return this.http.patch(url, bodyArray, { headers });
  }

  patchUpdateClinicAdministrator(
    id: number,
    form: UntypedFormGroup
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/clinicadministrators/${id}`;
    return this.http.patch<any>(url, createPatchBody(form));
  }
  createUser(form: UntypedFormGroup): Observable<CoAbbreviatedStaffUserDto> {
    const url = `${this.coApiUrl}centraloffices/users`;
    return this.http.post<CoAbbreviatedStaffUserDto>(
      url,
      createInsertBody(form)
    );
  }
  updateUser(
    userId: number,
    form: UntypedFormGroup
  ): Observable<CoAbbreviatedStaffUserDto> {
    const url = `${this.coApiUrl}centraloffices/users/${userId}`;
    return this.http.put<CoAbbreviatedStaffUserDto>(
      url,
      createUpdateBody(form)
    );
  }
  updateEntityClinicSecurityRoles(
    data: CoUserSecurityRoleParameters
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/security`;
    return this.http.put<any>(url, data);
  }
  updateEntitySecurityRoles(
    entityId: number,
    securityRoleIds: number[]
  ): Observable<SecurityRoleDto[]> {
    const url = `${this.coApiUrl}centraloffices/securityrole/entities/${entityId}/memberships`;
    return this.http.put<SecurityRoleDto[]>(url, securityRoleIds);
  }

  //#endregion
  //#region service->details-> case templates/cases/protocol templates
  getCoCaseTemplateForManagement(
    parameters: CaseTemplateForManagementCoSearchParameters
  ): Observable<CaseTemplateForCoManagementDto[]> {
    const params = this.helper.toQueryStringParams(parameters);
    const data = { ...params };
    const url = `${this.coApiUrl}centraloffices/casetemplates/management`;
    let res = this.http.get<CaseTemplateForCoManagementDto[]>(url, data);
    return res.pipe(map((response) => response.body || []));
  }
  getProtocolTemplatesForServiceManagement(
    parameters: CaseTemplateForManagementCoSearchParameters
  ): Observable<ProtocolTemplatesForServiceManagementDto[]> {
    const params = this.helper.toQueryStringParams(parameters);
    const data = { ...params };
    const url = `${this.coApiUrl}centraloffices/casephasetreatementprotocoltemplates/service/management`;
    let res = this.http.get<ProtocolTemplatesForServiceManagementDto[]>(
      url,
      data
    );
    return res.pipe(map((response) => response.body || []));
  }
  changeTreatmentPlanServiceRates(form: UntypedFormGroup): any {
    const url = `${this.coApiUrl}centraloffices/casetemplates/management/changerate`;
    return this.http.post(url, createInsertBody(form));
  }

  changeProtocolServiceRates(form: UntypedFormGroup): any {
    const url = `${this.coApiUrl}centraloffices/casetemplates/management/protocol/changerate`;
    return this.http.post(url, createInsertBody(form));
  }

  changeServiceRateByFranchise(serviceId: number, model: any): any {
    const url = `${this.coApiUrl}centraloffices/services/${serviceId}/changerate`;
    return this.http.post(url, model);
  }

  //#endregion

  getV2CaseTemplateForCoManagement(
    model: CaseProtocolTemplateForManagementCoSearchParameters
  ): Observable<ClientCasesForProtocolTemplateCoManagementDto[]> {
    const url = `${this.coApiUrl}centraloffices/v2/casephasetreatementprotocoltemplates/management`;
    return this.http
      .get<ClientCasesForProtocolTemplateCoManagementDto[]>(
        url,
        this.helper.toQueryStringParams(model)
      )
      .pipe(map((response) => response.body || []));
  }

  redistributePhaseFromProtocolsV2(model: {
    clientCasePhaseIds: number[];
    coCasePhaseTreatementProtocolTemplateId: number;
    coClinicId: number;
  }) {
    const url = `${this.coApiUrl}centraloffices/v2/casephasetreatementprotocoltemplates/redistribute/details`;
    return this.http.post<ClientCasesForProtocolTemplateCoManagementDto[]>(
      url,
      model
    );
  }
  //#region  roles
  getEntitySecurityRoleByClinic(
    webUserId: number
  ): Observable<SecurityRoleByClinicDto[]> {
    const url = `${this.coApiUrl}centraloffices/security/user/${webUserId}/roles`;
    return this.http.get<SecurityRoleByClinicDto[]>(url);
  }
  getSecurityRolesDropDownListItem(
    sysApplicationId: SysApplications
  ): Observable<SecurityRoleDropdownListItemDto[]> {
    const url = `${this.coApiUrl}security/roles/dropdownlist?sysApplicationId=${sysApplicationId}`;
    return this.http.get<SecurityRoleDropdownListItemDto[]>(url);
  }
  getEntitySecurityRoleFranchiseLevelMemberships(
    entityId: number
  ): Observable<SecurityRoleDto[]> {
    const url = `${this.coApiUrl}centraloffices/securityrole/entities/${entityId}/memberships`;
    return this.http.get<SecurityRoleDto[]>(url);
  }

  //#endregion

  getCoCaseTemplates_V2(
    model: CoCaseTemplateFilterParameters
  ): Observable<ClientCaseDto[]> {
    const url = `${this.coApiUrl}centraloffices/v2/casetemplates`;
    return this.http
      .get<ClientCaseDto[]>(url, this.helper.toQueryStringParams(model))
      .pipe(map((response) => response.body || []));
  }
  getCaseTemplate(
    clientCaseTemplateId: number,
    clinicId: number
  ): Observable<ClientCaseDto> {
    if (!clientCaseTemplateId) of(null);
    const url = `${this.coApiUrl}casetemplates/${clientCaseTemplateId}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.get<ClientCaseDto>(url, {
      headers,
    });
  }
  getCaseIntegrationTypesDropDownListItem(
    clinicId: number
  ): Observable<CaseIntegrationTypeDropDownListItemDto[]> {
    const url = `${this.coApiUrl}caseintegrationtypes/dropdownlist`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.get<CaseIntegrationTypeDropDownListItemDto[]>(url, {
      headers,
    });
  }
  getCaseIntegrationTypes(clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}caseintegrationtypes`;
    return this.http.get<CaseIntegrationType[]>(url, { headers });
  }
  getProcessQueueDropDownListItem(): Observable<DropDownListItemDto[]> {
    const url = `${this.coApiUrl}processqueues/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }

  getCountryStatesDropDownListItem(
    countryId: number
  ): Observable<DropDownListItemDto[]> {
    const url = `${this.coApiUrl}syscountries/${countryId}/sysstates/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }
  getSysStates(sysCountryId: number): Observable<SysStateDto[]> {
    const url = `${this.coApiUrl}syscountries/${sysCountryId}/sysstates`;
    return this.http.get<SysStateDto[]>(url);
  }
  duplicateCaseTemplate(
    id: number,
    model: any,
    clinicId: number
  ): Observable<ClientCaseDto> {
    const url = `${this.coApiUrl}casetemplates/${id}/duplicate`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.post<ClientCaseDto>(url, model, { headers });
  }
  updateCaseTemplate(
    id: number,
    model: any,
    clinicId: number
  ): Observable<ClientCaseDto> {
    const url = `${this.coApiUrl}casetemplates/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.put<ClientCaseDto>(url, model, { headers });
  }
  createCaseTemplate(model: any, clinicId: number): Observable<ClientCaseDto> {
    const url = `${this.coApiUrl}casetemplates`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.post<ClientCaseDto>(url, model, { headers });
  }
  deleteCaseTemplateFromClinic(id: number, clinicId: number): Observable<any> {
    const url = `${this.coApiUrl}casetemplates/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.delete<any>(url, { headers });
  }
  //#region Practitioners
  getPractitionersByClinic(model: CoParameters): any {
    const url = `${this.coApiUrl}centraloffices/practitioners/abbreviated/search`;
    return this.http
      .get<AbbreviatedEntityDto[]>(url, this.helper.toQueryStringParams(model))
      .pipe(map((response) => response.body || []));
  }
  getPractitionerListByClinic(clinicId: number) {
    const url = `${this.coApiUrl}practitioners/dropdownlist/all`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.get<ActiveDropDownListItemDto[]>(url, {
      headers: headers,
    });
  }
  getPractitioner(practitionerId: number): Observable<CompletePractitionerDto> {
    const url = `${this.coApiUrl}centraloffices/practitioners/${practitionerId}`;
    return this.http.get<CompletePractitionerDto>(url);
  }
  getAcctPractitionerCompensationSetupByClinic(
    practitionerId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}practitioners/${practitionerId}/compensations/setupbyclinic`;
    return this.http.get<AcctPractitionerCompensationSetupByClinicDto>(url, {
      headers,
    });
  }

  insertAcctPractitionerCompensationSetupByClinic(
    form: UntypedFormGroup,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}practitioners/${
      form.get('practitionerId')?.value
    }/compensations/setupbyclinic`;
    return this.http.post<AcctPractitionerCompensationSetupByClinicDto>(
      url,
      createInsertBody(form),
      { headers }
    );
  }

  patchAcctPractitionerCompensationSetupByClinic(
    form: UntypedFormGroup,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}practitioners/${
      form.get('practitionerId')?.value
    }/compensations/setupbyclinic`;
    return this.http.patch<AcctPractitionerCompensationSetupByClinicDto>(
      url,
      createPatchBody(form),
      { headers }
    );
  }
  postProductPaymentModeException(
    practitionerCompensationSetupByClinicId: number,
    obj: any
  ) {
    const url = `${this.coApiUrl}practitioners/compensations/setupbyclinic/${practitionerCompensationSetupByClinicId}/productPaymentModeException/batch`;
    return this.http.post<any>(url, obj);
  }

  postServicePaymentModeException(
    practitionerCompensationSetupByClinicId: number,
    obj: any
  ) {
    const url = `${this.coApiUrl}practitioners/compensations/setupbyclinic/${practitionerCompensationSetupByClinicId}/servicePaymentModeException/batch`;
    return this.http.post<any>(url, obj);
  }
  deleteProductPaymentModeException(
    practitionerCompensationSetupByClinicId: number,
    productId: number
  ) {
    const url = `${this.coApiUrl}practitioners/compensations/setupbyclinic/${practitionerCompensationSetupByClinicId}/productPaymentModeException/${productId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  deleteServicePaymentModeException(
    practitionerCompensationSetupByClinicId: number,
    serviceId: number
  ) {
    const url = `${this.coApiUrl}practitioners/compensations/setupbyclinic/${practitionerCompensationSetupByClinicId}/servicePaymentModeException/${serviceId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  getClinicAdministrator(
    clinicAdministratorId: number
  ): Observable<CoClinicAdministratorDto> {
    const url = `${this.coApiUrl}centraloffices/clinicadministrators/${clinicAdministratorId}`;
    return this.http.get<CoClinicAdministratorDto>(url);
  }

  publishPractitionerBio(practitionerId: number): Observable<PractitionerDto> {
    const url = `${this.coApiUrl}practitioners/${practitionerId}/publishbio`;
    return this.http.put<PractitionerDto>(url, null);
  }
  updateAdminPractitioner(
    practitionerId: number,
    pract: any
  ): Observable<CoPractitionerDto> {
    const url = `${this.coApiUrl}centraloffices/practitioners/${practitionerId}`;
    const res = this.http.put<CoPractitionerDto>(url, pract);
    return res;
  }
  partiallyUpdateAdminPractitioner(
    practitionerId: number,
    patchBody: any
  ): any {
    const url = `${this.coApiUrl}centraloffices/practitioners/admin/${practitionerId}`;
    const res = this.http.patch<PractitionerDto>(url, patchBody);
    return res;
  }
  patchUpdateAdminPractitioner(
    id: number,
    form: UntypedFormGroup
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/practitioners/admin/${id}`;
    return this.http.patch<any>(url, createPatchBody(form));
  }
  updateClinicAdministrator(
    clinicAdministratorId: number,
    admin: CoClinicAdministratorUpdateDto
  ): Observable<ClinicAdministratorDto> {
    const url = `${this.coApiUrl}centraloffices/clinicadministrators/${clinicAdministratorId}`;
    return this.http.put<ClinicAdministratorDto>(url, admin);
  }
  createPractitioner(
    practitioner: CoAdminPractitionerCreationDto
  ): Observable<CoPractitionerDto> {
    const url = `${this.coApiUrl}centraloffices/practitioners`;
    return this.http.post<CoPractitionerDto>(url, practitioner);
  }

  //#endregion Practitioners

  distributeCaseTemplatesByClinicsV2(
    data: DistributeCaseTemplatesParameters
  ): Observable<DistributedResults[]> {
    const url = `${this.coApiUrl}centraloffices/v2/casetemplates/distribute`;
    return this.http.post<DistributedResults[]>(url, data);
  }
  getV2ClinicsDropDownForDistributedCoCaseTemplate(
    caseTemplateId: number
  ): Observable<DistributedCaseTemplateDropDownListItemDto[]> {
    const url = `${this.coApiUrl}centraloffices/v2/casetemplates/${caseTemplateId}/byclinic/dropdownlist`;
    return this.http.get<DistributedCaseTemplateDropDownListItemDto[]>(url);
  }
  deleteCaseTemplateFromClinicV2(
    caseTemplateId: number,
    clinicIds: number[]
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/v2/casetemplates/${caseTemplateId}/deletefromclinic`;
    return this.http.delete<any>(url, { observe: 'response', body: clinicIds });
  }
  //#region  admin_staff
  getClinicAdministratorsByClinic(
    param: CoParameters
  ): Observable<AbbreviatedEntityDto[]> {
    let params = new HttpParams()
      .append('id', param.id.toString())
      .append('includeDeleted', param.includeDeleted.toString());
    if (param.pageNumber) {
      params.append('pageNumber', param.pageNumber.toString());
    }
    if (param.pageSize) {
      params.append('pageSize', param.pageSize.toString());
    }
    const url = `${this.coApiUrl}centraloffices/clinicadministrators/abbreviated/search`;
    return this.http.get<AbbreviatedEntityDto[]>(url, { params });
  }
  deleteClinicAdministrator(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/clinicadministrators/${id}`;
    return this.http.delete<any>(url);
  }
  activiateClinicAdministrator(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/clinicadministrators/${id}/activate`;
    return this.http.post<any>(url, null);
  }
  createClinicAdministrator(
    admin: CoClinicAdministratorCreationDto
  ): Observable<ClinicAdministratorDto> {
    const url = `${this.coApiUrl}centraloffices/clinicadministrators`;
    return this.http.post<ClinicAdministratorDto>(url, admin);
  }
  partiallyUpdateClinicAdministrator(
    clinicAdministratorId: number,
    form: FormGroup
  ): Observable<ClinicAdministratorDto> {
    const url = `${this.coApiUrl}centraloffices/clinicadministrators/${clinicAdministratorId}`;
    return this.http.patch<ClinicAdministratorDto>(url, createPatchBody(form));
  }
  //#endregion

  deleteCaseTemplatePayer(
    caseTemplateId: number,
    clientCaseFunderId: number,
    coClinicId: number
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', coClinicId.toString());
    const url = `${this.coApiUrl}cases/${caseTemplateId}/funders/${clientCaseFunderId}`;
    return this.http.delete<any>(url, { headers });
  }
  getCoFundersDropDownListItem(): Observable<DropDownListItemDto[]> {
    const url = `${this.coApiUrl}centraloffices/funders/co/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }
  getEmploymentStatusesDropDownListItem(): Observable<DropDownListItemDto[]> {
    const url = `${this.coApiUrl}sysemploymentstatuses/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }
  getDaySheetStyles(): Observable<SysDaySheetStyleDto[]> {
    const url = `${this.coApiUrl}sysdaysheetstyles`;
    return this.http.get<SysDaySheetStyleDto[]>(url);
  }
  getSysPractitionerPaymentModes(clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}syspractitionerpaymentmodes`;
    return this.http.get<SysPractitionerPaymentModeDto[]>(url, { headers });
  }
  getRevenueDivisions() {
    const url = `${this.coApiUrl}revenuedivisions`;
    return this.http.get<RevenueDivisionDto[]>(url);
  }
  getRevenueDivisionsDropdown() {
    const url = `${this.coApiUrl}revenuedivisions/dropdownlist`;
    return this.http.get<RevenueDivisionDropdownListItemDto[]>(url);
  }
  updateCaseTemplateFunder(
    caseTemplateId: number,
    funderId: number,
    model: any,
    coClinicId: number
  ): Observable<ClientCaseFunderDto> {
    const url = `${this.coApiUrl}cases/${caseTemplateId}/funders/${funderId}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', coClinicId.toString());
    return this.http.put<ClientCaseFunderDto>(url, model, { headers });
  }
  createCaseTemplateFunder(
    caseTemplateId: number,
    model: any,
    coClinicId: number
  ): Observable<ClientCaseFunderDto> {
    const url = `${this.coApiUrl}cases/${caseTemplateId}/funders`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', coClinicId.toString());
    return this.http.post<ClientCaseFunderDto>(url, model, { headers });
  }

  reorderCaseFunders(
    caseId: number,
    caseFunders: any,
    coClinicId: number
  ): Observable<any> {
    const url = `${this.coApiUrl}cases/${caseId}/funders/reorder`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', coClinicId.toString());
    return this.http.put<any>(url, caseFunders, { headers });
  }

  getClientCasePhases(
    clientCaseId: number,
    coClinicId: number
  ): Observable<ClientCasePhaseDto[]> {
    if (!clientCaseId) {
      return EMPTY;
    }
    const url = `${this.coApiUrl}cases/${clientCaseId}/casephases`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', coClinicId.toString());
    return this.http.get<ClientCasePhaseDto[]>(url, { headers });
  }
  getCOCasePhasesDropDownListItem(): Observable<SortedDropDownListItemDto[]> {
    const url = `${this.coApiUrl}centraloffices/casephases/co/dropdownlist`;
    return this.http.get<SortedDropDownListItemDto[]>(url);
  }

  patchCaseTemplate(
    clientCaseId: number,
    caseParams: UntypedFormGroup,
    coClinicId: number
  ): Observable<ClientCaseDto> {
    const url = `${this.coApiUrl}casetemplates/${clientCaseId}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', coClinicId.toString());
    return this.http.patch<ClientCaseDto>(url, createPatchBody(caseParams), {
      headers,
    });
  }
  createClientPhase(
    clientCaseId: number,
    clientPhase: ClientCasePhaseDto,
    coClinicId: number
  ): Observable<ClientCasePhaseDto> {
    const url = `${this.coApiUrl}cases/${clientCaseId}/casephases`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', coClinicId.toString());
    return this.http.post<ClientCasePhaseDto>(url, clientPhase, { headers });
  }

  updateClientPhase(
    clientCaseId: number,
    clientCasePhaseId: number,
    clientPhase: ClientCasePhaseDto,
    coClinicId: number
  ): Observable<ClientCasePhaseDto> {
    const url = `${this.coApiUrl}cases/${clientCaseId}/casephases/${clientCasePhaseId}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', coClinicId.toString());
    return this.http.put<ClientCasePhaseDto>(url, clientPhase, { headers });
  }
  deleteClientPhase(
    clientCaseId: number,
    clientCasePhaseId: number,
    coClinicId: number
  ): Observable<any> {
    const url = `${this.coApiUrl}cases/${clientCaseId}/casephases/${clientCasePhaseId}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', coClinicId.toString());
    return this.http.delete<ClientCasePhaseDto>(url, {
      observe: 'response',
      headers: headers,
    });
  }

  reorderClientCasePhases(
    clientCaseId: number,
    reorderedPhases: ClientCasePhaseReorderDto[],
    coClinicId: number
  ) {
    const url = `${this.coApiUrl}cases/${clientCaseId}/casephases/reorder`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', coClinicId.toString());
    return this.http.post<any>(url, reorderedPhases, { headers });
  }

  activateClientPhase(
    clientCaseId: number,
    clientCasePhaseId: number,
    coClinicId: number,
    firceActivate = false
  ): Observable<ClientCasePhaseDto> {
    let params = new HttpParams();
    if (firceActivate) {
      params = params.append('continue', 'true');
    }
    const url = `${this.coApiUrl}cases/${clientCaseId}/casephases/${clientCasePhaseId}/activate`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', coClinicId.toString());
    return this.http.put<ClientCasePhaseDto>(url, null, { params, headers });
  }
  patchClinicGroupFranchise(
    form: UntypedFormGroup
  ): Observable<ClinicGroupFranchiseDto> {
    const url = `${this.coApiUrl}clinicgroupfranchises/myclinicgroupfranchise`;
    return this.http.patch<ClinicGroupFranchiseDto>(url, createPatchBody(form));
  }

  getGLAccounts(): Observable<AcctGlaccountDto[]> {
    const url = `${this.coApiUrl}acctglaccounts`;
    return this.http.get<AcctGlaccountDto[]>(url);
  }
  getAcctTaxSetups(): Observable<AcctTaxSetupDto[]> {
    const url = `${this.coApiUrl}accttaxsetups`;
    return this.http.get<AcctTaxSetupDto[]>(url);
  }
  getAcctTaxSetup(taxSetupId: number): Observable<AcctTaxSetupDto> {
    const url = `${this.coApiUrl}accttaxsetups/${taxSetupId}`;
    return this.http.get<AcctTaxSetupDto>(url);
  }
  patchTaxSetup(form: UntypedFormGroup): Observable<AcctTaxSetupDto> {
    const url = `${this.coApiUrl}accttaxsetups/${
      form.get('taxSetupId')?.value
    }`;
    return this.http.patch<AcctTaxSetupDto>(url, createPatchBody(form));
  }
  insertTaxSetup(form: UntypedFormGroup): Observable<AcctTaxSetupDto> {
    const url = `${this.coApiUrl}accttaxsetups`;
    return this.http.post<AcctTaxSetupDto>(url, createInsertBody(form));
  }
  deleteAcctTaxSetup(taxSetupId: number) {
    const url = `${this.coApiUrl}accttaxsetups/${taxSetupId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  getTaxRateSetups(taxSetupId: number): Observable<AcctTaxRateSetupDto[]> {
    const url = `${this.coApiUrl}accttaxratesetups/${taxSetupId}`;
    return this.http.get<AcctTaxRateSetupDto[]>(url);
  }
  putTaxRateSetup(
    form: UntypedFormGroup,
    taxSetupId: number
  ): Observable<AcctTaxRateSetupDto> {
    const url = `${this.coApiUrl}accttaxratesetups/${taxSetupId}`;
    return this.http.put<AcctTaxRateSetupDto>(url, form.value);
  }
  postTaxRateSetup(
    form: UntypedFormGroup,
    taxSetupId: number
  ): Observable<AcctTaxRateSetupDto> {
    const url = `${this.coApiUrl}accttaxratesetups/${taxSetupId}`;
    return this.http.post<AcctTaxRateSetupDto>(url, createInsertBody(form));
  }
  deleteTaxRateSetup(id: number, date: string): Observable<any> {
    const url = `${this.coApiUrl}accttaxratesetups/${id}?effectiveDate=${date}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  getAcctPractitionerCompensationSetupTaxes(): Observable<
    AcctPractitionerCompensationSetupTaxDto[]
  > {
    const url = `${this.coApiUrl}practitionercompensationsetuptaxes`;
    return this.http.get<AcctPractitionerCompensationSetupTaxDto[]>(url);
  }

  insertAcctPractitionerCompensationSetupTax(
    form: UntypedFormGroup
  ): Observable<AcctPractitionerCompensationSetupTaxDto> {
    const url = `${this.coApiUrl}practitionercompensationsetuptaxes`;
    return this.http.post<AcctPractitionerCompensationSetupTaxDto>(
      url,
      createInsertBody(form)
    );
  }

  patchAcctPractitionerCompensationSetupTax(
    form: UntypedFormGroup
  ): Observable<AcctPractitionerCompensationSetupTaxDto> {
    const url = `${this.coApiUrl}practitionercompensationsetuptaxes/${
      form.get('id')?.value
    }`;
    return this.http.patch<AcctPractitionerCompensationSetupTaxDto>(
      url,
      createPatchBody(form)
    );
  }

  deleteAcctPractitionerCompensationSetupTaxe(id: number): Observable<any> {
    const url = `${this.coApiUrl}practitionercompensationsetuptaxes/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  getAcctPractitionerCompensationSetupTaxRates(
    setupTaxeId: number
  ): Observable<AcctPractitionerCompensationSetupTaxRateDto[]> {
    const url = `${this.coApiUrl}practitionercompensationsetuptaxes/${setupTaxeId}/rates`;
    return this.http.get<AcctPractitionerCompensationSetupTaxRateDto[]>(url);
  }

  getCompensationPractitionersSummaries(clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}practitioners/summary/compensation`;
    return this.http.get<AcctPractitionerCompensationSummaryDto[]>(url, {
      headers,
    });
  }

  getAcctPractitionerCompensationSetupExceptionsBySetupId(
    setupId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationsetups/${setupId}/exceptions`;
    return this.http.get<AcctPractitionerCompensationSetupExceptionDto[]>(url, {
      headers,
    });
  }

  getAcctPractitionerCompensationSetupsByPractitionerId(
    practitionerId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationsetups/practitioner/${practitionerId}`;
    return this.http.get<AcctPractitionerCompensationSetupDto[]>(url, {
      headers,
    });
  }

  // AcctPractitionerCompensationSetupOut
  getAcctPractitionerCompensationSetupOutBySetupId(
    setupId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}practitionercompensationsetups/${setupId}/outs`;
    return this.http.get<AcctPractitionerCompensationSetupOutDto[]>(url, {
      headers,
    });
  }

  insertAcctPractitionerCompensationSetupTaxRate(
    setupTaxeId: number,
    form: UntypedFormGroup
  ): Observable<AcctPractitionerCompensationSetupTaxRateDto> {
    const url = `${this.coApiUrl}practitionercompensationsetuptaxes/${setupTaxeId}/rates`;
    return this.http.post<AcctPractitionerCompensationSetupTaxRateDto>(
      url,
      createInsertBody(form)
    );
  }

  patchAcctPractitionerCompensationSetupTaxRate(
    form: UntypedFormGroup
  ): Observable<AcctPractitionerCompensationSetupTaxRateDto> {
    const url = `${this.coApiUrl}practitionercompensationsetuptaxes/${
      form.get('practitionerCompensationSetupTaxeId')?.value
    }/rates/${form.get('effectiveDate')?.value}`;
    return this.http.patch<AcctPractitionerCompensationSetupTaxRateDto>(
      url,
      createPatchBody(form)
    );
  }

  deleteAcctPractitionerCompensationSetupTaxRate(
    setupTaxeId: number,
    effectiveDate: string
  ): Observable<any> {
    const url = `${this.coApiUrl}practitionercompensationsetuptaxes/${setupTaxeId}/rates/${effectiveDate}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  getAcctPractitionerCompensationCategory(): Observable<
    AcctPractitionerCompensationSetupCategoryDto[]
  > {
    const url = `${this.coApiUrl}centraloffices/accounting/practitioners/compensations/categories?includeInactive=true`;
    return this.http.get<AcctPractitionerCompensationSetupCategoryDto[]>(url);
  }
  getAcctPractitionerCompensationSetupCategoryDetails(
    practitionerCompensationCategoryId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}accounting/practitioners/compensations/categories/${practitionerCompensationCategoryId}/details`;
    return this.http.get<AcctPractitionerCompensationImportfromPackageDto>(
      url,
      { headers }
    );
  }
  getAcctPractitionerCompensationsDropdown() {
    const url = `${this.coApiUrl}compensationsetups/dropdown`;
    return this.http.get<AcctPractitionerCompensationSetupDropDownListDto[]>(
      url
    );
  }

  insertAcctPractitionerCompensationException(
    clinicId: number,
    setupId: number,
    compensationExceptions: AcctPractitionerCompensationSetupExceptionCreation
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationsetups/${setupId}/exception/batch`;
    return this.http.post<AcctPractitionerCompensationSetupExceptionDto>(
      url,
      compensationExceptions,
      { headers }
    );
  }
  getAcctPractitionerCompensationSetupsBySetupId(
    setupId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationsetups/${setupId}`;
    return this.http.get<AcctPractitionerCompensationSetupDto>(url, {
      headers,
    });
  }
  getAcctPractitionerCompensationSetupDropDownList(
    sysPractitionerPaymentModeId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationsetups/dropdown/${sysPractitionerPaymentModeId}`;
    return this.http.get<AcctPractitionerCompensationSetupDropDownListDto[]>(
      url,
      { headers }
    );
  }
  insertAcctPractitionerCompensationSetups(
    clinicId: number,
    compensationSetupCreation: AcctPractitionerCompensationSetupGeneral
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationsetups`;
    return this.http.post<AcctPractitionerCompensationSetupDto>(
      url,
      compensationSetupCreation,
      { headers }
    );
  }

  putAcctPractitionerCompensationSetups(
    clinicId: number,
    setupId: number,
    compensationSetupUpdate: AcctPractitionerCompensationSetupGeneral
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationsetups/${setupId}`;
    return this.http.put<AcctPractitionerCompensationSetupDto>(
      url,
      compensationSetupUpdate,
      { headers }
    );
  }

  patchAcctPractitionerCompensationSetups(
    form: UntypedFormGroup,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationsetups/${form.get('id')?.value}`;
    return this.http.patch<AcctPractitionerCompensationSetupDto>(
      url,
      createPatchBody(form),
      { headers }
    );
  }

  insertAcctPractitionerCompensationSetupOuts(
    clinicId: number,
    setupId: number,
    compensationOuts: AcctPractitionerCompensationSetupOutBatch
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationsetups/${setupId}/out/batch`;
    return this.http.post<AcctPractitionerCompensationSetupOutDto>(
      url,
      compensationOuts,
      { headers }
    );
  }
  insertAcctPractitionerCompensationSetupCategoryMemberships(
    clinicId: number,
    setupId: number,
    compensationMembershipCategory: AcctPractitionerCompensationSetupCategoryMembershipsBatch
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationsetups/${setupId}/memberships/batch`;
    return this.http.post<AcctPractitionerCompensationSetupCategoryMemberships>(
      url,
      compensationMembershipCategory,
      { headers }
    );
  }
  getAcctPractitionerCompensationSetupCategoryMemberships(
    practitionerCompensationCategoryId: number
  ): Observable<number[]> {
    const url = `${this.coApiUrl}centraloffices/accounting/practitioners/compensations/categories/${practitionerCompensationCategoryId}/memberships`;
    return this.http.get<number[]>(url);
  }

  getPractitionerCompensationSetupCategoryServices(
    practitionerCompensationCategoryId: number
  ): Observable<AcctPractitionerCompensationSetupCategoryServiceDto[]> {
    const url = `${this.coApiUrl}centraloffices/accounting/practitioners/compensations/categories/${practitionerCompensationCategoryId}/services`;
    return this.http.get<AcctPractitionerCompensationSetupCategoryServiceDto[]>(
      url
    );
  }
  deletepractitionerCompensationCategory(id: number): Observable<any> {
    const url = `${this.coApiUrl}accounting/practitioners/compensations/categories/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  getPractitionerCompensationSetupCategoryProducts(
    practitionerCompensationCategoryId: number
  ): Observable<AcctPractitionerCompensationSetupCategoryProductDto[]> {
    const url = `${this.coApiUrl}centraloffices/accounting/practitioners/compensations/categories/${practitionerCompensationCategoryId}/products`;
    return this.http.get<AcctPractitionerCompensationSetupCategoryProductDto[]>(
      url
    );
  }

  // getpractitionerCompensationCategoryId(
  //   practitionerCompensationCategoryId: number
  // ): Observable<AcctPractitionerCompensationSetupCategoryDto> {
  //   const url = `${this.coApiUrl}accounting/practitioners/compensations/categories/${practitionerCompensationCategoryId}`;
  //   return this.http.get<AcctPractitionerCompensationSetupCategoryDto>(url);
  // }
  postAcctPractitionerCompensationCategory(
    form: UntypedFormGroup
  ): Observable<AcctPractitionerCompensationSetupCategoryDto> {
    const url = `${this.coApiUrl}accounting/practitioners/compensations/categories`;
    return this.http.post<AcctPractitionerCompensationSetupCategoryDto>(
      url,
      createInsertBody(form)
    );
  }
  patchAcctPractitionerCompensationCategory(
    form: UntypedFormGroup
  ): Observable<AcctPractitionerCompensationSetupCategoryDto> {
    const url = `${
      this.coApiUrl
    }accounting/practitioners/compensations/categories/${
      form.get('practitionerCompensationCategoryId')?.value
    }`;
    return this.http.patch<AcctPractitionerCompensationSetupCategoryDto>(
      url,
      createPatchBody(form)
    );
  }
  getActiveAcctPractitionerCompensationSetupCategories() {
    const url = `${this.coApiUrl}accounting/practitioners/compensations/categories`;
    return this.http.get<AcctPractitionerCompensationSetupCategoryDto[]>(url);
  }
  batchPractitionerCompensationSetupCategoryServices(
    services: AcctPractitionerCompensationSetupCategoryServiceDto[],
    practitionerCompensationCategoryId: number
  ): Observable<AcctPractitionerCompensationSetupCategoryServiceDto[]> {
    const url = `${this.coApiUrl}centraloffices/accounting/practitioners/compensations/categories/${practitionerCompensationCategoryId}/services`;
    return this.http.post<
      AcctPractitionerCompensationSetupCategoryServiceDto[]
    >(url, services);
  }
  deletePractitionerCompensationSetupCategoryServices(
    practitionerCompensationCategoryId: number,
    serviceId: number,
    service: AcctPractitionerCompensationSetupCategoryServiceDto
  ): Observable<AcctPractitionerCompensationSetupCategoryServiceDto> {
    const url = `${this.coApiUrl}centraloffices/accounting/practitioners/compensations/categories/${practitionerCompensationCategoryId}/services/${serviceId}/endvalidation`;
    return this.http.put<AcctPractitionerCompensationSetupCategoryServiceDto>(
      url,
      service
    );
  }
  batchPractitionerCompensationSetupCategoryProducts(
    products: AcctPractitionerCompensationSetupCategoryProductDto[],
    practitionerCompensationCategoryId: number
  ): Observable<AcctPractitionerCompensationSetupCategoryProductDto[]> {
    const url = `${this.coApiUrl}centraloffices/accounting/practitioners/compensations/categories/${practitionerCompensationCategoryId}/products`;
    return this.http.post<
      AcctPractitionerCompensationSetupCategoryProductDto[]
    >(url, products);
  }

  deletePractitionerCompensationSetupCategoryProducts(
    practitionerCompensationCategoryId: number,
    productId: number,
    product: AcctPractitionerCompensationSetupCategoryProductDto
  ): Observable<AcctPractitionerCompensationSetupCategoryProductDto> {
    const url = `${this.coApiUrl}centraloffices/accounting/practitioners/compensations/categories/${practitionerCompensationCategoryId}/products/${productId}/endvalidation`;
    return this.http.put<AcctPractitionerCompensationSetupCategoryProductDto>(
      url,
      product
    );
  }

  //region quotes / quotes categories
  getProcessQueueCategories(): Observable<ProcessQueueCategoryDto[]> {
    const url = `${this.coApiUrl}processqueuecategories`;
    return this.http.get<ProcessQueueCategoryDto[]>(url);
  }

  insertProcessQueueCategory(
    form: UntypedFormGroup
  ): Observable<ProcessQueueCategoryDto> {
    const url = `${this.coApiUrl}processqueuecategories`;
    return this.http.post<ProcessQueueCategoryDto>(url, createInsertBody(form));
  }

  patchProcessQueueCategory(
    form: UntypedFormGroup
  ): Observable<ProcessQueueCategoryDto> {
    const url = `${this.coApiUrl}processqueuecategories/${
      form.get('processQueueCategoryId')?.value
    }`;
    return this.http.patch<ProcessQueueCategoryDto>(url, createPatchBody(form));
  }

  deleteProcessQueueCategory(processQueueCategoryId: number): Observable<any> {
    const url = `${this.coApiUrl}processqueuecategories/${processQueueCategoryId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  getProcessQueues(): Observable<ProcessQueueDto[]> {
    const url = `${this.coApiUrl}processqueues`;
    return this.http.get<ProcessQueueDto[]>(url);
  }

  insertProcessQueues(form: UntypedFormGroup): Observable<ProcessQueueDto> {
    const url = `${this.coApiUrl}processqueues`;
    return this.http.post<ProcessQueueDto>(url, createInsertBody(form));
  }

  patchProcessQueues(form: UntypedFormGroup): Observable<ProcessQueueDto> {
    const url = `${this.coApiUrl}processqueues/${
      form.get('processQueueId')?.value
    }`;
    return this.http.patch<ProcessQueueDto>(url, createPatchBody(form));
  }

  deleteProcessQueues(processQueueId: number): Observable<any> {
    const url = `${this.coApiUrl}processqueues/${processQueueId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  getProcessQueueCategoryDtoDropdown(): Observable<DropDownListItemDto[]> {
    const url = `${this.coApiUrl}processqueuecategories/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }
  getMonthlyPeriodsDropDownListItem(): Observable<DropDownListItemDto[]> {
    const url = `${this.coApiUrl}sysmonthlyperiods/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }

  reorderQueueCategories(
    rows: ProcessQueueCategoryDto[]
  ): Observable<ProcessQueueCategoryDto[]> {
    const url = `${this.coApiUrl}processqueuecategories/reorder`;
    return this.http.put<ProcessQueueCategoryDto[]>(
      url,
      (() => {
        const bodyArray = [];

        for (let i = 0; i < rows.length; i++) {
          bodyArray.push({
            id: rows[i].processQueueCategoryId,
            order: i,
          });
        }

        return bodyArray;
      })()
    );
  }

  reorderQueues(rows: ProcessQueueDto[]): Observable<ProcessQueueDto[]> {
    const url = `${this.coApiUrl}processqueues/reorder`;
    return this.http.put<ProcessQueueDto[]>(
      url,
      (() => {
        const bodyArray = [];

        for (let i = 0; i < rows.length; i++) {
          bodyArray.push({
            id: rows[i].processQueueId,
            order: i,
          });
        }

        return bodyArray;
      })()
    );
  }
  //endregion quotes / quotes categories

  //case phase categories
  getCasePhaseCategories() {
    const url = `${this.coApiUrl}casephasecategories/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }
  //#region membership
  getMembershipPlans(includeInactive: boolean, includeUnAssociated: boolean) {
    let params = new HttpParams()
      .append('includeInactive', includeInactive.toString())
      .append('includeUnAssociated', includeUnAssociated.toString());

    const url = `${this.coApiUrl}centraloffices/membership/plans`;
    return this.http.get<MembershipPlanDto[]>(url, { params });
  }
  getCoMembershipPlan(id: number): Observable<MembershipPlanDto> {
    const url = `${this.coApiUrl}centraloffices/membership/plans/${id}`;
    return this.http.get<MembershipPlanDto>(url);
  }
  deleteCoMembershipPlan(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/membership/plans/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  getCoMembershipPlanEligibleServicesWithMaxValidTo(membershipPlanId: number) {
    const url = `${this.coApiUrl}centraloffices/membership/plans/${membershipPlanId}/eligibles/services`;
    return this.http.get<MembershipPlanEligibleServiceDto[]>(url);
  }
  getCoMembershipPlanEligibleProductsWithMaxValidTo(membershipPlanId: number) {
    const url = `${this.coApiUrl}centraloffices/membership/plans/${membershipPlanId}/eligibles/products`;
    return this.http.get<MembershipPlanEligibleProductDto[]>(url);
  }
  createCoBatchMembershipPlanEligibleService(
    membershipPlanId: number,
    ids: CoMembershipPlanEligibleProductOrServiceBatchCreationDto
  ) {
    const url = `${this.coApiUrl}centraloffices/membership/plans/${membershipPlanId}/eligibles/services/batch`;
    return this.http.post<MembershipPlanEligibleServiceDto[]>(url, ids);
  }
  endValidationCoMembershipPlanEligibleService(
    membershipPlanId: number,
    serviceId: number
  ) {
    const url = `${this.coApiUrl}centraloffices/membership/plans/${membershipPlanId}/eligibles/services/${serviceId}/endvalidation`;
    return this.http.put<any>(url, null);
  }

  createCoBatchMembershipPlanEligibleProduct(
    membershipPlanId: number,
    ids: CoMembershipPlanEligibleProductOrServiceBatchCreationDto
  ) {
    const url = `${this.coApiUrl}centraloffices/membership/plans/${membershipPlanId}/eligibles/products/batch`;
    return this.http.post<MembershipPlanEligibleProductDto[]>(url, ids);
  }
  endValidationCoMembershipPlanEligibleProduct(
    membershipPlanId: number,
    productsId: number
  ) {
    const url = `${this.coApiUrl}centraloffices/membership/plans/${membershipPlanId}/eligibles/products/${productsId}/endvalidation`;
    return this.http.put<any>(url, null);
  }
  createMembershipPlan(form: UntypedFormGroup) {
    const url = `${this.coApiUrl}centraloffices/membership/plans`;
    return this.http.post<MembershipPlanDto>(url, createInsertBody(form));
  }
  updateMembershipPlan(membershipPlanId: number, form: UntypedFormGroup) {
    const url = `${this.coApiUrl}centraloffices/membership/plans/${membershipPlanId}`;
    return this.http.put<MembershipPlanDto>(url, createUpdateBody(form));
  }
  GetCoClinicsByMembershipPlan(membershipPlanId: number) {
    const url = `${this.coApiUrl}centraloffices/membership/plans/${membershipPlanId}/clinics`;
    return this.http.get<CoAbbreviatedMembershipPlanByClinicDto[]>(url);
  }
  deleteCoMembershipPlanFromClinic(
    membershipPlanId: number,
    clinicId: number
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/membership/plans/${membershipPlanId}/deletefromclinic/${clinicId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  distributeCoMembershipPlanByClinics(
    params: DistributeMembershipPlanParameters
  ): Observable<DistributeMembershipPlanResults[]> {
    const url = `${this.coApiUrl}centraloffices/membership/plans/distribute`;
    return this.http.post<DistributeMembershipPlanResults[]>(url, params);
  }
  //#endregion

  // Communication types

  insertClinicFranchiseSupportedNotificationTypes(
    supportedTypes: ClinicFranchiseSupportedNotificationTypeDto[],
    clinicId: number
  ): Observable<ClinicFranchiseSupportedNotificationTypeDto[]> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicfranchisesupportedappointmentnotificationtypes`;
    return this.http.post<ClinicFranchiseSupportedNotificationTypeDto[]>(
      url,
      supportedTypes,
      { headers }
    );
  }

  getClinicFranchiseSupportedNotificationTypes(): Observable<
    ClinicFranchiseSupportedNotificationTypeDto[]
  > {
    const url = `${this.coApiUrl}clinicfranchisesupportedappointmentnotificationtypes`;
    return this.http.get<ClinicFranchiseSupportedNotificationTypeDto[]>(url);
  }

  getSysAppointmentNotificationTypes(): Observable<
    SysAppointmentNotificationTypeDto[]
  > {
    const url = `${this.coApiUrl}sysappointmentnotificationtypes`;
    return this.http.get<SysAppointmentNotificationTypeDto[]>(url);
  }

  // end comminication types
  //#region revenue ranges
  getCoFranchiseeCompensationRevenuRanges() {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationrevenuranges`;
    return this.http.get<CoFranchiseeCompensationRevenuRangeDto[]>(url);
  }
  deleteCoFranchiseeCompensationRevenuRange(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationrevenuranges/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  createCoFranchiseeCompensationRevenuRange(form: UntypedFormGroup) {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationrevenuranges`;
    return this.http.post<CoFranchiseeCompensationRevenuRangeDto>(
      url,
      createInsertBody(form)
    );
  }
  updateCoFranchiseeCompensationRevenuRange(
    Id: number,
    form: UntypedFormGroup
  ) {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationrevenuranges/${Id}`;
    return this.http.put<CoFranchiseeCompensationRevenuRangeDto>(
      url,
      createUpdateBody(form)
    );
  }
  //#endregion

  //region franchise fee management
  getCoFranchiseeCompensationHistories(
    prm: any
  ): Observable<FranchiseeCompensationHistoryDto[]> {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationhistories`;
    return this.http
      .get<FranchiseeCompensationHistoryDto[]>(
        url,
        this.helper.toQueryStringParams(prm)
      )
      .pipe(map((response) => response.body || []));
  }

  markAsPaid(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationhistories/${id}`;
    return this.http.post<ProcessQueueDto>(url, { observe: 'response' });
  }

  addCoFranchiseeCompensationHistory(
    model: CoFranchiseeCompensationHistoryCreationDto
  ): Observable<number> {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationhistories`;
    return this.http.post<number>(url, model);
  }
  deleteCoFranchiseeCompensationHistorie(
    id: number,
    clinicId: number
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationhistories/${id}/clinic/${clinicId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  //end region
  //#region  franchisecompensationproductandcategories

  getCoFranchiseeCompensationProductCategories() {
    const url = `${this.coApiUrl}centraloffices/franchisecompensationproductcategories`;
    return this.http.get<CoFranchiseeCompensationProductCategoryDto[]>(url);
  }
  getCoFranchiseeCompensationProducts() {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationproducts`;
    return this.http.get<CoFranchiseeCompensationProductDto[]>(url);
  }
  deleteCoFranchiseeCompensationProduct(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationproducts/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  createCoFranchiseeCompensationProduct(
    form: UntypedFormGroup
  ): Observable<CoFranchiseeCompensationProductDto> {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationproducts`;
    return this.http.post<CoFranchiseeCompensationProductDto>(
      url,
      createInsertBody(form)
    );
  }
  updateCoFranchiseeCompensationProduct(Id: number, form: UntypedFormGroup) {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationproducts/${Id}`;
    return this.http.put<CoFranchiseeCompensationProductDto>(
      url,
      createUpdateBody(form)
    );
  }
  reOrderCoFranchiseeCompensationProductsCategories(
    params: OrderParameters[]
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchisecompensationproductcategories/reorder`;
    return this.http.put(url, params);
  }
  deleteCoFranchiseeCompensationProductCategory(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchisecompensationproductcategories/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  partiallyUpdateCoFranchiseeCompensationProductCategory(
    id: number,
    form: UntypedFormGroup
  ): Observable<CoFranchiseeCompensationProductCategoryDto> {
    const url = `${this.coApiUrl}centraloffices/franchisecompensationproductcategories/${id}`;
    return this.http.patch<CoFranchiseeCompensationProductCategoryDto>(
      url,
      createPatchBody(form)
    );
  }
  createCoFranchiseeCompensationProductCategory(
    form: UntypedFormGroup
  ): Observable<CoFranchiseeCompensationProductCategoryDto> {
    const url = `${this.coApiUrl}centraloffices/franchisecompensationproductcategories`;
    return this.http.post<CoFranchiseeCompensationProductCategoryDto>(
      url,
      createInsertBody(form)
    );
  }
  reOrderCoFranchiseeCompensationProducts(
    params: OrderParameters[]
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationproducts/reorder`;
    return this.http.put(url, params);
  }
  getCoFranchiseeCompensationProductCategoriesDisciplines(categoryId: number) {
    const url = `${this.coApiUrl}centraloffices/franchisecompensationproductcategoriesdisciplines/${categoryId}`;
    return this.http.get<
      CoFranchiseeCompensationProductCategoriesDisciplineDto[]
    >(url);
  }
  getCoFranchiseeCompensationProductCategoriesProductCategory(
    categoryId: number
  ) {
    const url = `${this.coApiUrl}centraloffices/franchisecompensationproductcategoriesproductategory/${categoryId}`;
    return this.http.get<
      CoFranchiseeCompensationProductCategoriesProductCategoryDto[]
    >(url);
  }
  deleteCoFranchiseeCompensationProductCategoriesDiscipline(
    franchiseCategoryId: number,
    disciplineId: number
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchisecompensationproductcategoriesdisciplines/${franchiseCategoryId}/discipline/${disciplineId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  deleteCoFranchiseeCompensationProductCategoriesProductCategorie(
    franchiseCategoryId: number,
    categoryId: number
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchisecompensationproductcategoriesproductategory/${franchiseCategoryId}/productcategory/${categoryId}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  createCoFranchiseeCompensationProductCategoriesProductCategory(
    franchiseCategoryId: number,
    params: CoFranchiseeCompensationProductCategoriesProductCategoryCreationDto[]
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchisecompensationproductcategoriesproductategory/${franchiseCategoryId}`;
    return this.http.post(url, params);
  }
  createCoFranchiseeCompensationProductCategoriesDiscipline(
    franchiseCategoryId: number,
    params: CoFranchiseeCompensationProductCategoriesDisciplineCreationDto[]
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchisecompensationproductcategoriesdisciplines/${franchiseCategoryId}`;
    return this.http.post(url, params);
  }

  //#endregion
  //#region clinics_setup
  getCoFranchiseeCompensationSetups(clinicId: number) {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationsetups/clinic/${clinicId}`;
    return this.http.get<CoFranchiseeCompensationSetupDto[]>(url);
  }
  getCoFranchiseeCompensationSetup(id: number) {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationsetups/${id}`;
    return this.http.get<CoFranchiseeCompensationSetupDto>(url);
  }
  createCoFranchiseeCompensationSetup(form: UntypedFormGroup) {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationsetups`;
    return this.http.post<CoFranchiseeCompensationSetupDto>(
      url,
      createInsertBody(form)
    );
  }
  updateCoFranchiseeCompensationSetup(id: number, form: UntypedFormGroup) {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationsetups/${id}`;
    return this.http.put<CoFranchiseeCompensationSetupDto>(
      url,
      createUpdateBody(form)
    );
  }
  deleteCoFranchiseeCompensationSetup(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationsetups/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  copyClinicSetupsFromAnotherClinic(
    parameters: CoClinicCopyParameters
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/franchiseecompensationsetups/copy`;
    return this.http.post(url, parameters);
  }

  //#endregion
  getEducationalResourceCategories(): Observable<
    EducationalContentCategoryDto[]
  > {
    const url = `${this.coApiUrl}educationalcontentcategories`;
    return this.http.get<EducationalContentCategoryDto[]>(url);
  }

  insertEducationalResourceCategory(
    form: UntypedFormGroup
  ): Observable<EducationalContentCategoryDto> {
    const url = `${this.coApiUrl}educationalcontentcategories`;
    return this.http.post<EducationalContentCategoryDto>(
      url,
      createInsertBody(form)
    );
  }

  patchEducationalResourceCategory(
    form: UntypedFormGroup
  ): Observable<EducationalContentCategoryDto> {
    const url = `${this.coApiUrl}educationalcontentcategories/${
      form.get('educationalContentCategoryId')?.value
    }`;
    return this.http.patch<EducationalContentCategoryDto>(
      url,
      createPatchBody(form)
    );
  }

  deleteEducationalResourceCategory(id: number): Observable<any> {
    const url = `${this.coApiUrl}educationalcontentcategories/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  orderEducationalResourceCategory(
    categories: EducationalContentCategoryDto[]
  ): Observable<EducationalContentCategoryDto[]> {
    const url = `${this.coApiUrl}educationalcontentcategories/order`;
    return this.http.put<EducationalContentCategoryDto[]>(
      url,
      (() => {
        const bodyArray = [];

        for (let i = 0; i < categories.length; i++) {
          bodyArray.push({
            id: categories[i].educationalContentCategoryId,
            order: i,
          });
        }

        return bodyArray;
      })()
    );
  }

  mergeEducationalResourceCategory(
    from: number,
    to: number
  ): Observable<EducationalContentCategoryDto> {
    const url = `${this.coApiUrl}educationalcontentcategories/merge`;
    return this.http.post<EducationalContentCategoryDto>(url, {
      idMergeFrom: from,
      idMergeTo: to,
    });
  }

  getAllEducationalContents(): Observable<EducationalContentDto[]> {
    const url = `${this.coApiUrl}educationalcontents`;
    return this.http.get<EducationalContentDto[]>(url);
  }

  getEducationalContent(id: number): Observable<EducationalContentDto> {
    const url = `${this.coApiUrl}educationalcontents/${id}`;
    return this.http.get<EducationalContentDto>(url);
  }

  deleteEducationalContent(contentId: number): Observable<void> {
    const url = `${this.coApiUrl}educationalcontents/${contentId}`;
    return this.http.delete<void>(url);
  }

  putEducationalContent(
    educationalContentId: number,
    putEducationalResource: any
  ): Observable<EducationalContentDto> {
    const url = `${this.coApiUrl}educationalcontents/${educationalContentId}`;
    return this.http.put<EducationalContentDto>(url, putEducationalResource);
  }

  insertEducationalContent(
    form: UntypedFormGroup
  ): Observable<EducationalContentDto> {
    const headers = new HttpHeaders({ 'Content-Type': '' });
    const url = `${this.coApiUrl}educationalcontents`;
    return this.http.post<EducationalContentDto>(url, createPutFormData(form), {
      headers,
    });
  }

  getEducationalContentInvitations(
    clientId: number
  ): Observable<EducationalContentInvitationDto[]> {
    const url = `${this.coApiUrl}clients/${clientId}/educationalcontentinvitations`;
    return this.http.get<EducationalContentInvitationDto[]>(url);
  }

  exportToPdfBase64(model: ReportDto): Observable<any> {
    const url = `${this.reportApiUrl}reports/pdf/base64`;
    //return this.http.post<ReportDto>(url, { observe: 'response' });
    return this.http
      .post<any>(url, model, {
        responseType: 'text' as 'json',
      })
      .pipe(
        map((response) => {
          return response || '';
        })
      );
  }

  getChartNoteTemplateCategories(): Observable<ChartNoteTemplateCategoryDto[]> {
    const url = `${this.coApiUrl}chartnotes/templates/categories`;
    return this.http.get<ChartNoteTemplateCategoryDto[]>(url);
  }

  deleteChartNoteTemplateCategory(id: number): Observable<any> {
    const url = `${this.coApiUrl}chartnotes/templates/categories/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  deleteChartNoteTemplateSubCategory(id: number): Observable<any> {
    const url = `${this.coApiUrl}chartnotes/templates/subcategories/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  orderChartNoteTemplateCategory(
    categories: ChartNoteTemplateCategoryDto[]
  ): Observable<ChartNoteTemplateCategoryDto[]> {
    const url = `${this.coApiUrl}chartnotes/templates/categories/updateorder`;
    return this.http.put<ChartNoteTemplateCategoryDto[]>(
      url,
      (() => {
        const bodyArray = [];

        for (let i = 0; i < categories.length; i++) {
          bodyArray.push({
            id: categories[i].chartNoteTemplateCategoryId,
            order: i,
          });
        }

        return bodyArray;
      })()
    );
  }

  orderChartNoteTemplateSubCategory(
    subCategories: ChartNoteTemplateSubCategoryDto[]
  ): Observable<ChartNoteTemplateSubCategoryDto[]> {
    const url = `${this.coApiUrl}chartnotes/templates/subcategories/updateorder`;
    return this.http.put<ChartNoteTemplateSubCategoryDto[]>(
      url,
      (() => {
        const bodyArray = [];

        for (let i = 0; i < subCategories.length; i++) {
          bodyArray.push({
            id: subCategories[i].chartNoteTemplateSubCategoryId,
            order: i,
          });
        }

        return bodyArray;
      })()
    );
  }
  patchChartNoteTemplateCategory(
    form: UntypedFormGroup
  ): Observable<ChartNoteTemplateCategoryDto> {
    const url = `${this.coApiUrl}chartnotes/templates/categories/${
      form.get('chartNoteTemplateCategoryId')?.value
    }`;
    return this.http.patch<ChartNoteTemplateCategoryDto>(
      url,
      createPatchBody(form)
    );
  }

  insertChartNoteTemplateCategory(
    form: UntypedFormGroup
  ): Observable<ChartNoteTemplateCategoryDto> {
    const url = `${this.coApiUrl}chartnotes/templates/categories`;
    return this.http.post<ChartNoteTemplateCategoryDto>(
      url,
      createInsertBody(form)
    );
  }
  patchChartNoteTemplateSubCategory(
    form: UntypedFormGroup
  ): Observable<ChartNoteTemplateSubCategoryDto> {
    const url = `${this.coApiUrl}chartnotes/templates/subcategories/${
      form.get('chartNoteTemplateSubCategoryId')?.value
    }`;
    return this.http.patch<ChartNoteTemplateSubCategoryDto>(
      url,
      createPatchBody(form)
    );
  }
  insertChartNoteTemplateSubCategory(
    form: UntypedFormGroup
  ): Observable<ChartNoteTemplateSubCategoryDto> {
    const url = `${this.coApiUrl}chartnotes/templates/subcategories`;
    return this.http.post<ChartNoteTemplateSubCategoryDto>(
      url,
      createInsertBody(form)
    );
  }
  //#region pick_list
  getRequiredTableFieldCandidates(): Observable<
    SysRequiredTableFieldCandidateDto[]
  > {
    const url = `${this.coApiUrl}requiredtablefieldcandidates`;
    return this.http.get<SysRequiredTableFieldCandidateDto[]>(url);
  }

  putSysRequiredTableFieldCandidates(
    sysRequiredTableFieldCandidateId: number,
    isRequired: boolean
  ): Observable<SysRequiredTableFieldCandidateDto> {
    const url = `${this.coApiUrl}requiredtablefieldcandidates/${sysRequiredTableFieldCandidateId}/setRequired`;
    return this.http.put<SysRequiredTableFieldCandidateDto>(url, isRequired);
  }
  getCities(): Observable<CityDto[]> {
    const url = `${this.coApiUrl}cities`;
    return this.http.get<CityDto[]>(url);
  }
  insertCity(form: UntypedFormGroup): Observable<CityDto> {
    const url = `${this.coApiUrl}cities`;
    return this.http.post<CityDto>(url, createInsertBody(form));
  }

  patchCity(form: UntypedFormGroup): Observable<CityDto> {
    const url = `${this.coApiUrl}cities/${form.get('cityId')?.value}`;
    return this.http.patch<CityDto>(url, createPatchBody(form));
  }
  mergeCity(from: number, to: number): Observable<CityDto> {
    const url = `${this.coApiUrl}cities/merge`;
    return this.http.post<CityDto>(url, { idMergeFrom: from, idMergeTo: to });
  }
  deleteCity(id: number): Observable<any> {
    const url = `${this.coApiUrl}cities/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  //#endregion

  getClientSysCustomFields(): Observable<SysCustomFieldDto[]> {
    const url = `${this.coApiUrl}syscustomfields/clients`;
    return this.http.get<SysCustomFieldDto[]>(url);
  }

  getClientCustomFields(): Observable<CustomFieldDto[]> {
    const url = `${this.coApiUrl}customfields/clients`;
    return this.http.get<CustomFieldDto[]>(url);
  }

  patchCustomField(form: UntypedFormGroup): Observable<CustomFieldDto> {
    const url = `${this.coApiUrl}customfields/${
      form.get('customFieldId')?.value
    }`;
    return this.http.patch<CustomFieldDto>(url, createPatchBody(form));
  }

  deleteCustomFieldComboValue(
    customFieldId: number,
    id: number
  ): Observable<any> {
    const url = `${this.coApiUrl}customfields/${customFieldId}/combovalues/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  mergeCustomFieldComboValue(
    customFieldId: number,
    from: number,
    to: number
  ): Observable<CustomFieldComboValueDto> {
    const url = `${this.coApiUrl}customfields/${customFieldId}/combovalues/merge`;
    return this.http.post<CustomFieldComboValueDto>(url, {
      idMergeFrom: from,
      idMergeTo: to,
    });
  }

  patchCustomFieldComboValue(
    customFieldId: number,
    form: UntypedFormGroup
  ): Observable<CustomFieldComboValueDto> {
    const url = `${this.coApiUrl}customfields/${customFieldId}/combovalues/${
      form.get('customFieldComboValueId')?.value
    }`;
    return this.http.patch<CustomFieldComboValueDto>(
      url,
      createPatchBody(form)
    );
  }

  insertCustomFieldComboValue(
    customFieldId: number,
    form: UntypedFormGroup
  ): Observable<CustomFieldComboValueDto> {
    const url = `${this.coApiUrl}customfields/${customFieldId}/combovalues`;
    return this.http.post<CustomFieldComboValueDto>(
      url,
      createInsertBody(form)
    );
  }

  insertCustomFields(
    customFieldTargetTable: number
  ): Observable<CustomFieldDto[]> {
    const url = `${this.coApiUrl}customfields/insert?table=${customFieldTargetTable}`;
    return this.http.post<CustomFieldDto[]>(url, {});
  }
  getAppointmentCustomFields(): Observable<CustomFieldDto[]> {
    const url = `${this.coApiUrl}customfields/appointments`;
    return this.http.get<CustomFieldDto[]>(url);
  }

  getAppointmentSysCustomFields(): Observable<SysCustomFieldDto[]> {
    const url = `${this.coApiUrl}syscustomfields/appointments`;
    return this.http.get<SysCustomFieldDto[]>(url);
  }

  getCaseCustomFields(): Observable<CustomFieldDto[]> {
    const url = `${this.coApiUrl}customfields/clientcases`;
    return this.http.get<CustomFieldDto[]>(url);
  }

  getOutReasons(): Observable<OutReasonDto[]> {
    const url = `${this.coApiUrl}outreasons`;
    return this.http.get<OutReasonDto[]>(url);
  }

  insertOutReason(form: UntypedFormGroup): Observable<OutReasonDto> {
    const url = `${this.coApiUrl}outreasons`;
    return this.http.post<OutReasonDto>(url, createInsertBody(form));
  }

  patchOutReason(form: UntypedFormGroup): Observable<OutReasonDto> {
    const url = `${this.coApiUrl}outreasons/${form.get('outReasonId')?.value}`;
    return this.http.patch<OutReasonDto>(url, createPatchBody(form));
  }

  deleteOutReason(id: number): Observable<any> {
    const url = `${this.coApiUrl}outreasons/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  mergeOutReason(from: number, to: number): Observable<OutReasonDto> {
    const url = `${this.coApiUrl}outreasons/merge`;
    return this.http.post<OutReasonDto>(url, {
      idMergeFrom: from,
      idMergeTo: to,
    });
  }

  getOutReasonDefaultRefs(): Observable<DefaultOutReasonDto[]> {
    const url = `${this.coApiUrl}outreasons/defaults`;
    return this.http.get<DefaultOutReasonDto[]>(url);
  }

  setOutReasonDefaultRefs(ids?: number[]): Observable<OutReasonDto[]> {
    const url = `${this.coApiUrl}outreasons/insert`;
    return this.http.post<OutReasonDto[]>(url, ids);
  }
  getEquipment(clinicId: number): Observable<EquipmentDto[]> {
    const url = `${this.coApiUrl}equipments`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.get<EquipmentDto[]>(url, {
      headers,
    });
  }

  insertEquipment(
    clinicId: number,
    form: UntypedFormGroup
  ): Observable<EquipmentDto> {
    const url = `${this.coApiUrl}equipments`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.post<EquipmentDto>(url, createInsertBody(form), {
      headers,
    });
  }

  patchEquipment(
    clinicId: number,
    form: UntypedFormGroup
  ): Observable<EquipmentDto> {
    const url = `${this.coApiUrl}equipments/${form.get('equipmentId')?.value}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());

    return this.http.patch<EquipmentDto>(url, createPatchBody(form), {
      headers,
    });
  }

  deleteEquipment(clinicId: number, id: number): Observable<any> {
    const url = `${this.coApiUrl}equipments/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());

    return this.http.delete<any>(url, {
      observe: 'response',
      headers: headers,
    });
  }

  mergeEquipment(
    clinicId: number,
    from: number,
    to: number
  ): Observable<EquipmentDto> {
    const url = `${this.coApiUrl}equipments/merge`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());

    return this.http.post<EquipmentDto>(
      url,
      { idMergeFrom: from, idMergeTo: to },
      { headers }
    );
  }

  // getEquipmentDropdown(): Observable<RelatedItemDto[]> {
  //   const url = `${this.coApiUrl}equipments/dropdownlist`;
  //   return this.http.get<RelatedItemDto[]>(url).pipe(
  //     map(apiEquipments => {
  //       const equipment = [...apiEquipments];
  //       equipment.unshift({
  //         id: 0,
  //         name: localStorage.getItem(Identifier.TYPE_LANGUAGE) === 'en-CA' ? 'N/A' : 'N/D',
  //       });
  //       return equipment;
  //     })
  //   );
  // }
  getRooms(clinicId: number): Observable<RoomDto[]> {
    const url = `${this.coApiUrl}rooms`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.get<RoomDto[]>(url, {
      headers,
    });
  }

  insertRoom(clinicId: number, form: UntypedFormGroup): Observable<RoomDto> {
    const url = `${this.coApiUrl}rooms`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());

    return this.http.post<RoomDto>(url, createInsertBody(form), { headers });
  }

  patchRoom(clinicId: number, form: UntypedFormGroup): Observable<RoomDto> {
    const url = `${this.coApiUrl}rooms/${form.get('roomId')?.value}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());

    return this.http.patch<RoomDto>(url, createPatchBody(form), {
      headers,
    });
  }

  deleteRoom(clinicId: number, id: number): Observable<any> {
    const url = `${this.coApiUrl}rooms/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());

    return this.http.delete<any>(url, {
      observe: 'response',
      headers: headers,
    });
  }

  mergeRoom(clinicId: number, from: number, to: number): Observable<RoomDto> {
    const url = `${this.coApiUrl}rooms/merge`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());

    return this.http.post<RoomDto>(
      url,
      { idMergeFrom: from, idMergeTo: to },
      { headers }
    );
  }

  // getRoomsDropdown(): Observable<RelatedItemDto[]> {
  //   const url = `${this.coApiUrl}rooms/dropdownlist`;
  //   return this.http.get<RelatedItemDto[]>(url).pipe(
  //     map(apiRooms => {
  //       const apRooms = [...apiRooms];
  //       apRooms.unshift({
  //         id: 0,
  //         name: localStorage.getItem(Identifier.TYPE_LANGUAGE) === 'en-CA' ? 'N/A' : 'N/D',
  //       });
  //       return apRooms;
  //     })
  //   );
  // }

  getRoomDefaultRefs(clinicId: number): Observable<DefaultRoomDto[]> {
    const url = `${this.coApiUrl}rooms/defaults`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());

    return this.http.get<DefaultRoomDto[]>(url, { headers });
  }

  setRoomDefaultRefs(ids?: number[]): Observable<RoomDto[]> {
    const url = `${this.coApiUrl}rooms/insert`;
    return this.http.post<RoomDto[]>(url, ids);
  }
  getRoomReservationReasons(
    clinicId: number
  ): Observable<RoomReservationReasonDto[]> {
    const url = `${this.coApiUrl}roomreservationreasons`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.get<RoomReservationReasonDto[]>(url, {
      headers,
    });
  }

  insertRoomReservationReason(
    form: UntypedFormGroup
  ): Observable<RoomReservationReasonDto> {
    const url = `${this.coApiUrl}roomreservationreasons`;
    return this.http.post<RoomReservationReasonDto>(
      url,
      createInsertBody(form)
    );
  }

  patchRoomReservationReason(
    form: UntypedFormGroup
  ): Observable<RoomReservationReasonDto> {
    const url = `${this.coApiUrl}roomreservationreasons/${
      form.get('roomReservationReasonId')?.value
    }`;
    return this.http.patch<RoomReservationReasonDto>(
      url,
      createPatchBody(form)
    );
  }

  deleteRoomReservationReason(id: number): Observable<any> {
    const url = `${this.coApiUrl}roomreservationreasons/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  mergeRoomReservationReason(
    from: number,
    to: number
  ): Observable<RoomReservationReasonDto> {
    const url = `${this.coApiUrl}roomreservationreasons/merge`;
    return this.http.post<RoomReservationReasonDto>(url, {
      idMergeFrom: from,
      idMergeTo: to,
    });
  }

  getRoomReservationReasonDefaultRefs(): Observable<
    DefaultRoomReservationReasonDto[]
  > {
    const url = `${this.coApiUrl}roomreservationreasons/defaults`;
    return this.http.get<DefaultRoomReservationReasonDto[]>(url);
  }

  setRoomReservationReasonDefaultRefs(
    ids?: number[]
  ): Observable<RoomReservationReasonDto[]> {
    const url = `${this.coApiUrl}roomreservationreasons/insert`;
    return this.http.post<RoomReservationReasonDto[]>(url, ids);
  }
  getRegions(): Observable<RegionDto[]> {
    const url = `${this.coApiUrl}regions`;
    return this.http.get<RegionDto[]>(url);
  }
  getSubRegions(): Observable<SubRegionDto[]> {
    const url = `${this.coApiUrl}subregions`;
    return this.http.get<SubRegionDto[]>(url);
  }

  insertSubRegion(form: UntypedFormGroup): Observable<SubRegionDto> {
    const url = `${this.coApiUrl}subregions`;
    return this.http.post<SubRegionDto>(url, createInsertBody(form));
  }

  patchSubRegion(form: UntypedFormGroup): Observable<SubRegionDto> {
    const url = `${this.coApiUrl}subregions/${form.get('subRegionId')?.value}`;
    return this.http.patch<SubRegionDto>(url, createPatchBody(form));
  }

  deleteSubRegion(id: number): Observable<any> {
    const url = `${this.coApiUrl}subregions/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  getInterestGroups(): Observable<InterestGroupDto[]> {
    const url = `${this.coApiUrl}interestgroups`;
    return this.http.get<InterestGroupDto[]>(url);
  }
  getInterests(): Observable<InterestDto[]> {
    const url = `${this.coApiUrl}interests`;
    return this.http.get<InterestDto[]>(url);
  }

  insertInterest(form: UntypedFormGroup): Observable<InterestDto> {
    const url = `${this.coApiUrl}interests`;
    return this.http.post<InterestDto>(url, createInsertBody(form));
  }

  patchInterest(form: UntypedFormGroup): Observable<InterestDto> {
    const url = `${this.coApiUrl}interests/${form.get('interestId')?.value}`;
    return this.http.patch<InterestDto>(url, createPatchBody(form));
  }

  deleteInterest(id: number): Observable<any> {
    const url = `${this.coApiUrl}interests/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  mergeInterest(from: number, to: number): Observable<InterestDto> {
    const url = `${this.coApiUrl}interests/merge`;
    return this.http.post<InterestDto>(url, {
      idMergeFrom: from,
      idMergeTo: to,
    });
  }
  setInterestDefaultRefs(
    defaultSetupFormGroup: UntypedFormGroup
  ): Observable<InterestDto[]> {
    const url = `${this.coApiUrl}interests/insert`;
    return this.http.post<InterestDto[]>(
      url,
      createInsertBody(defaultSetupFormGroup)
    );
  }

  //#Web form templates

  getAllChartNoteTemplateForms() {
    const url = `${this.coApiUrl}/centraloffices/chartnotetemplateforms/all`;
    return this.http.get<ChartNoteTemplateFormDto[]>(url);
  }
  getChartNoteTypes() {
    const url = `${this.coApiUrl}chartnotetypes`;
    return this.http.get<ChartNoteTypeDto[]>(url);
  }

  getSysChartNoteTemplateFormsUnassociated() {
    const url = `${this.coApiUrl}/syschartnotetemplateforms/unassociated`;
    return this.http.get<UnassociatedChartNoteTemplateFormDto[]>(url);
  }

  addChartNoteTemplateForm(formData: ChartNoteTemplateFormRequestDto) {
    const url = `${this.coApiUrl}centraloffices/chartnotetemplateforms`;
    return this.http.post(url, formData);
  }

  editChartNoteTemplateForm(
    templateId: number,
    formData: Omit<ChartNoteTemplateFormRequestDto, 'clinicGroupFranchiseId'>
  ) {
    const url = `${this.coApiUrl}centraloffices/chartnotetemplateforms/${templateId}`;
    return this.http.put(url, formData);
  }

  deleteChartNoteTemplateForm(templateId: number) {
    const url = `${this.coApiUrl}centraloffices/chartnotetemplateforms/${templateId}`;
    return this.http.delete(url);
  }

  getTemplateFormClinics(templateFormId: number) {
    const url = `${this.coApiUrl}centraloffices/chartnotetemplateforms/${templateFormId}/allClinics`;
    return this.http.get<TemplateFormClinicDto[]>(url);
  }

  getChartNoteTemplateFomEntities(clinicId: number, templateFormId: number) {
    const url = `${this.coApiUrl}centraloffices/chartnotetemplateforms/${clinicId}/${templateFormId}/entities`;
    return this.http.get<TemplateFormEntityDto[]>(url);
  }

  setChartNoteTemplateFomEntities(
    clinicId: number,
    templateFormId: number,
    assignedEntityIds: number[]
  ) {
    const url = `${this.coApiUrl}centraloffices/chartnotetemplateforms/${clinicId}/${templateFormId}/entities`;
    return this.http.post(url, { assignedEntityIds });
  }

  getChartNoteTemplateBillableItems(clinicId: number, templateFormId: number) {
    const url = `${this.coApiUrl}centraloffices/chartnotetemplatebillableitemsonsignedoffs/${clinicId}/bytemplate/${templateFormId}?documentTypeId=PDFForm`;
    return this.http.get<BillableItemDto[]>(url);
  }

  getChartNoteTemplateClinicFunders(clinicId: number) {
    const url = `${this.coApiUrl}centraloffices/funders/${clinicId}/dropdownlist?includeDeleted=false&excludeFundersWithNoIntegrationType=false`;
    return this.http.get<TemplateFormClinicFunderDto[]>(url);
  }

  getChartNoteTemplateClinicPractitioners(clinicId: number) {
    const url = `${this.coApiUrl}centraloffices/practitioners/${clinicId}/dropdownlist/all`;
    return this.http.get<TemplateFormClinicPractitionerDto[]>(url);
  }

  createBillableItemOnSignoff(
    clinicId: number,
    billableItem: BillingItemRequestDto
  ) {
    const url = `${this.coApiUrl}centraloffices/chartnotetemplatesmartdocbillableitemsonsignedoffs/clinic/${clinicId}`;
    return this.http.post(url, billableItem);
  }

  editBillableItem(
    clinicId: number,
    billingItemId: number,
    billableItem: BillingItemRequestDto
  ) {
    const url = `${this.coApiUrl}centraloffices/chartnotetemplatesmartdocbillableitemsonsignedoffs/clinic/${clinicId}/${billingItemId}`;
    return this.http.put(url, billableItem);
  }

  deleteBillingItem(clinicId: number, billingItemId: number) {
    const url = `${this.coApiUrl}centraloffices/chartnotetemplatesmartdocbillableitemsonsignedoffs/clinic/${clinicId}`;
    return this.http.delete(url);
  }

  makeAllWebAndDfTemplateFormsVisible() {
    const url = `${this.coApiUrl}centraloffices/chartnotetemplateforms/makeallwebanddftemplateformsvisible`;
    return this.http.post(url, {});
  }

  getSysChartNoteTypes(): Observable<SysChartNoteType[]> {
    const url = `${this.coApiUrl}syschartnotetypes`;
    return this.http.get<SysChartNoteType[]>(url);
  }

  // end Webb form templates

  getInjuries(): Observable<InjuryDto[]> {
    const url = `${this.coApiUrl}injuries`;
    return this.http.get<InjuryDto[]>(url);
  }

  insertInjury(form: UntypedFormGroup): Observable<InjuryDto> {
    const url = `${this.coApiUrl}injuries`;
    return this.http.post<InjuryDto>(url, createInsertBody(form));
  }

  patchInjury(form: UntypedFormGroup): Observable<InjuryDto> {
    const url = `${this.coApiUrl}injuries/${form.get('injuryId')?.value}`;
    return this.http.patch<InjuryDto>(url, createPatchBody(form));
  }

  deleteInjury(id: number): Observable<any> {
    const url = `${this.coApiUrl}injuries/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  mergeInjury(from: number, to: number): Observable<InjuryDto> {
    const url = `${this.coApiUrl}injuries/merge`;
    return this.http.post<InjuryDto>(url, { idMergeFrom: from, idMergeTo: to });
  }
  getInjuryFamilies(): Observable<InjuryFamilyDto[]> {
    const url = `${this.coApiUrl}injuryfamilies`;
    return this.http.get<InjuryFamilyDto[]>(url);
  }
  getSysICDCodes(pattern: string): Observable<SysICDCodeDto[]> {
    const url = `${this.coApiUrl}sysicdcodes?pattern=${pattern}`;
    return this.http.get<SysICDCodeDto[]>(url);
  }
  getSysGroupForAnalyticCategories(): Observable<
    SysGroupForAnalyticCategoryDto[]
  > {
    const url = `${APIRoutesConfig.coApiUrl}sysgroupforanalyticcategories`;
    return this.http.get<SysGroupForAnalyticCategoryDto[]>(url);
  }
  getGroupForAnalytic(): Observable<GroupForAnalyticDto[]> {
    const url = `${APIRoutesConfig.coApiUrl}groupforanalytics`;
    return this.http.get<GroupForAnalyticDto[]>(url);
  }

  insertGroupForAnalytic(
    form: UntypedFormGroup
  ): Observable<GroupForAnalyticDto> {
    const url = `${APIRoutesConfig.coApiUrl}groupforanalytics`;
    return this.http.post<GroupForAnalyticDto>(url, createInsertBody(form));
  }

  patchGroupForAnalytic(
    form: UntypedFormGroup
  ): Observable<GroupForAnalyticDto> {
    const url = `${APIRoutesConfig.coApiUrl}groupforanalytics/${
      form.get('groupForAnalyticId')?.value
    }`;
    return this.http.patch<GroupForAnalyticDto>(url, createPatchBody(form));
  }

  deleteGroupForAnalytic(id: number): Observable<any> {
    const url = `${APIRoutesConfig.coApiUrl}groupforanalytics/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  //#region referring_groups
  getReferringGroupsDefaultRefs(): Observable<DefaultReferringGroup[]> {
    const url = `${this.coApiUrl}referringgroups/defaults`;
    return this.http.get<DefaultReferringGroup[]>(url);
  }

  setReferringGroupsDefaultRefs(
    defaultSetupFormGroup: UntypedFormGroup
  ): Observable<ReferringGroup[]> {
    const url = `${this.coApiUrl}referringgroups/insert`;
    return this.http.post<ReferringGroup[]>(
      url,
      createInsertBody(defaultSetupFormGroup)
    );
  }

  getReferringGroups(): Observable<ReferringGroup[]> {
    const url = `${this.coApiUrl}referringgroups`;
    return this.http.get<ReferringGroup[]>(url);
  }

  insertReferringGroup(form: UntypedFormGroup): Observable<ReferringGroup> {
    const url = `${this.coApiUrl}referringgroups`;
    return this.http.post<ReferringGroup>(url, createInsertBody(form));
  }

  patchReferringGroup(form: UntypedFormGroup): Observable<ReferringGroup> {
    const url = `${this.coApiUrl}referringgroups/${
      form.get('referringGroupId')?.value
    }`;
    return this.http.patch<ReferringGroup>(url, createPatchBody(form));
  }

  deleteReferringGroup(id: number): Observable<any> {
    const url = `${this.coApiUrl}referringgroups/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  mergeReferringGroup(from: number, to: number): Observable<ReferringGroup> {
    const url = `${this.coApiUrl}referringgroups/merge`;
    return this.http.post<ReferringGroup>(url, {
      idMergeFrom: from,
      idMergeTo: to,
    });
  }
  getReferringCategories(): Observable<ReferringCategory[]> {
    const url = `${this.coApiUrl}referringcategories`;
    return this.http.get<ReferringCategory[]>(url);
  }
  //#endregion

  getSysCalendarClientidentifierOptionsDDL(): Observable<
    DropDownListItemDto[]
  > {
    const url = `${this.coApiUrl}syscalendarclientidentifieroptions/dropdownlist`;
    return this.http.get<DropDownListItemDto[]>(url);
  }
  getControlCenterTabs(clinicId: number): Observable<ControlCenterTabDto[]> {
    const url = `${this.coApiUrl}centraloffice/controlcentertabs/coclinictabs/${clinicId}`;
    return this.http.get<ControlCenterTabDto[]>(url);
  }

  insertControlCenterTab(
    clinicId: number,
    form: UntypedFormGroup
  ): Observable<ControlCenterTabDto> {
    const url = `${this.coApiUrl}centraloffice/controlcentertabs/coclinic/${clinicId}`;
    return this.http.post<ControlCenterTabDto>(url, createInsertBody(form));
  }

  putControlCenterTab(
    tab: ControlCenterTabDto
  ): Observable<ControlCenterTabDto> {
    const url = `${this.coApiUrl}controlcentertabs/${tab.controlCenterTabId}`;
    return this.http.put<ControlCenterTabDto>(url, tab);
  }

  deleteControlCenterTab(id: number): Observable<any> {
    const url = `${this.coApiUrl}controlcentertabs/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  orderControlCenterTabs(
    clinicId: number,
    tabs: ControlCenterTabDto[]
  ): Observable<ControlCenterTabDto[]> {
    const url = `${this.coApiUrl}centraloffice/controlcentertabs/coordertabs/${clinicId}`;

    return this.http.put<ControlCenterTabDto[]>(
      url,
      (() => {
        const bodyArray = [];

        for (let i = 0; i < tabs.length; i++) {
          bodyArray.push({
            id: tabs[i].controlCenterTabId,
            order: i,
          });
        }

        return bodyArray;
      })()
    );
  }
  GetClinicAllPractitionersDropDownListItem(
    clinicId: number
  ): Observable<PractitionerDropDownListItemDto[]> {
    const url = `${this.coApiUrl}centraloffices/practitioners/clinic/${clinicId}/dropdownlist/all`;
    return this.http.get<PractitionerDropDownListItemDto[]>(url);
  }
  getPractitionersSimple(clinicId: number): Observable<PractitionerDto[]> {
    const url = `${this.coApiUrl}practitioners/simple`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.get<PractitionerDto[]>(url, {
      headers,
    });
  }
  getCMWebActiveUsers(
    clinicId: number,
    payload: number[]
  ): Observable<ActiveEntitiesDto[]> {
    const url = `${this.coApiUrl}entities/active`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());

    return this.http.post<ActiveEntitiesDto[]>(url, payload, { headers });
  }
  // getClinicUsers(clinicId: number): Observable<StaffUserDto[]> {
  //   const url = `${this.coApiUrl}centraloffices/users/clinic/${clinicId}`;

  //   return this.http.get<StaffUserDto[]>(url);
  // }
  getClinicUsersActive(clinicId: number): Observable<EntityUserFullNameDto[]> {
    const url = `${this.coApiUrl}centraloffices/users/clinic/${clinicId}/active`;

    return this.http.get<EntityUserFullNameDto[]>(url);
  }
  getClinicControlCenterColors(
    clinicId: number
  ): Observable<ClinicControlCenterColorDto[]> {
    const url = `${this.coApiUrl}centraloffice/cliniccontrolcentercolors/clinics/${clinicId}`;
    return this.http.get<ClinicControlCenterColorDto[]>(url);
  }

  getDefaultClinicControlCenterColor(): Observable<
    ClinicControlCenterColorDto[]
  > {
    const url = `${this.coApiUrl}cliniccontrolcentercolors/systemdefault`;
    return this.http.get<ClinicControlCenterColorDto[]>(url);
  }
  saveClinicControlCenterColors(
    model: COClinicControlCenterColorCreationDto
  ): Observable<COBatchSaveResponse[]> {
    const url = `${this.coApiUrl}centraloffice/cliniccontrolcentercolors/batchsave`;
    return this.http.post<COBatchSaveResponse[]>(url, model);
  }
  getSysReportStyle(
    sysReportCategoryId?: number
  ): Observable<SysReportStyleDto[]> {
    let params = new HttpParams();
    if (sysReportCategoryId) {
      params.append('sysReportCategoryId', sysReportCategoryId);
    }
    const url = `${this.coApiUrl}sysreportstyles`;
    return this.http.get<SysReportStyleDto[]>(url, { params });
  }
  getClinicApplicationSetup(
    clinicId: number
  ): Observable<ClinicApplicationSetupDto> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicapplicationsetups`;
    return this.http.get<ClinicApplicationSetupDto>(url, { headers });
  }
  getSysCalendarClientidentifierOptions(): Observable<DropDownListItemDto[]> {
    const url = `${this.coApiUrl}syscalendarclientidentifieroptions`;
    return this.http.get<DropDownListItemDto[]>(url);
  }
  getCaseDropdowns(clinicId: number): Observable<CaseDropDownListItemDto[]> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}casetemplates/dropdownlist/settings`;
    return this.http.get<CaseDropDownListItemDto[]>(url, { headers });
  }
  saveClinicApplicationSetup(
    model: CoClinicApplicationSetupSaveDto
  ): Observable<COBatchSaveResponse[]> {
    const url = `${this.coApiUrl}centraloffices/clinicapplicationsetups/clinicsetup`;
    return this.http.post<COBatchSaveResponse[]>(url, model);
  }
  //#region  openhours_closure
  getClinicHolidays(clinicId: number): Observable<ClinicHolidayDto[]> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicholidays`;
    return this.http.get<ClinicHolidayDto[]>(url, { headers });
  }

  insertClinicHoliday(
    clinicId: number,
    form: UntypedFormGroup
  ): Observable<ClinicHolidayDto> {
    const url = `${this.coApiUrl}clinicholidays`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.post<ClinicHolidayDto>(url, createInsertBody(form), {
      headers,
    });
  }

  patchClinicHoliday(
    clinicId: number,
    form: UntypedFormGroup
  ): Observable<ClinicHolidayDto> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicholidays/${
      form.get('clinicHolidayId')?.value
    }`;
    return this.http.patch<ClinicHolidayDto>(url, createPatchBody(form), {
      headers,
    });
  }

  deleteClinicHoliday(clinicId: number, id: number): Observable<any> {
    const url = `${this.coApiUrl}clinicholidays/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.delete<any>(url, {
      observe: 'response',
      headers: headers,
    });
  }

  getClinicHolidayDefaultRefs(
    clinicId: number
  ): Observable<ClinicHolidayDto[]> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicholidays/defaults`;
    return this.http.get<ClinicHolidayDto[]>(url, { headers });
  }

  setClinicHolidayDefaultRefs(
    clinicId: number,
    ids?: number[]
  ): Observable<ClinicHolidayDto[]> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicholidays/insert`;
    return this.http.post<ClinicHolidayDto[]>(url, ids, { headers });
  }
  getClinicOfficeHours(clinicId: number): Observable<ClinicOfficeHourDto[]> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicofficehours`;
    return this.http.get<ClinicOfficeHourDto[]>(url, { headers });
  }

  getEffectiveClinicOfficeHour(
    clinicId: number
  ): Observable<ClinicOfficeHourDto> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicofficehours/effective`;
    return this.http.get<ClinicOfficeHourDto>(url, { headers });
  }

  insertClinicOfficeHour(
    clinicId: number,
    officeHours: ClinicOfficeHourDto
  ): Observable<ClinicOfficeHourDto> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicofficehours`;
    return this.http.post<ClinicOfficeHourDto>(url, officeHours, { headers });
  }

  updateClinicOfficeHour(
    clinicId: number,
    officeHours: ClinicOfficeHourDto
  ): Observable<ClinicOfficeHourDto> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicofficehours/${officeHours.clinicOfficeHourId}`;
    return this.http.put<ClinicOfficeHourDto>(url, officeHours, { headers });
  }

  deleteClinicOfficeHour(
    clinicId: number,
    clinicOfficeHourId: number
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicofficehours/${clinicOfficeHourId}`;
    return this.http.delete<any>(url, {
      observe: 'response',
      headers: headers,
    });
  }
  //#endregion
  getMySetupClinic(clinicId: number): Observable<ClinicSetupDto> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinics/setup`;
    return this.http.get<ClinicSetupDto>(url, { headers });
  }
  getSysMeasurementSystems(): Observable<SysMeasurementSystemDto[]> {
    const url = `${this.coApiUrl}sysmeasurementsystems`;
    return this.http.get<SysMeasurementSystemDto[]>(url);
  }
  uploadClinicImages(
    clinicId: number,
    form: UntypedFormGroup
  ): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/images/clinic/${clinicId}`;
    const headers = new HttpHeaders({ 'Content-Type': '' });
    return this.http.post<any>(url, createPutFormData(form), { headers });
  }
  patchClinic(
    clinicId: number,
    form: UntypedFormGroup
  ): Observable<ClinicSetupDto> {
    const url = `${this.coApiUrl}centraloffices/clinics/setup/${clinicId}`;
    return this.http.patch<ClinicSetupDto>(url, createPatchBody(form));
  }
  getChartNoteSharingTeams(
    clinicId: number
  ): Observable<PractitionerChartNoteSharingTeamDto[]> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}practitionerchartnotesharingteams`;
    return this.http.get<PractitionerChartNoteSharingTeamDto[]>(url, {
      headers,
    });
  }
  postChartNoteSharingTeams(
    clinicId: number,
    payload: any
  ): Observable<PractitionerChartNoteSharingTeamDto> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}practitionerchartnotesharingteams`;
    return this.http.post<PractitionerChartNoteSharingTeamDto>(url, payload, {
      headers,
    });
  }

  putChartNoteSharingTeams(
    clinicId: number,
    form: UntypedFormGroup,
    payload: any
  ): Observable<PractitionerChartNoteSharingTeamDto> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}practitionerchartnotesharingteams/${
      form.get('practitionerChartNoteSharingTeamId')?.value
    }`;
    return this.http.put<PractitionerChartNoteSharingTeamDto>(url, payload, {
      headers,
    });
  }

  deleteChartNoteSharingTeam(clinicId: number, id: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}practitionerchartnotesharingteams/${id}`;
    return this.http.delete<any>(url, {
      observe: 'response',
      headers: headers,
    });
  }

  getPractitionersAndClinicAdministratorsAbbreviated(
    clinicId: number
  ): Observable<AbbreviatedEntityPractitionerClinicAdminDto[]> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}entities/abbreviated/practitionersclinicadministrators`;
    return this.http.get<AbbreviatedEntityPractitionerClinicAdminDto[]>(url, {
      headers,
    });
  }
  getAcctPractitionerCompensationForHolidaysByclinicholidayId(
    clinicholidayId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationforholidays/clinicholiday/${clinicholidayId}`;
    return this.http.get<AcctPractitionerCompensationForHolidayDto[]>(url, {
      headers,
    });
  }
  getAcctPractitionerCompensationSetupByClinics(clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}practitioners/compensations/setupbyclinic`;
    return this.http.get<AcctPractitionerCompensationSetupByClinicDto[]>(url, {
      headers,
    });
  }

  getAcctPractitionerCompensationForHolidays(
    practitionerId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationforholidays/practitioner/${practitionerId}`;
    return this.http.get<AcctPractitionerCompensationForHolidayDto[]>(url, {
      headers,
    });
  }

  getAcctPractitionerCompensationSetupByClinicById(
    id: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}practitioners/compensations/setupbyclinic/${id}`;
    return this.http.get<AccPractitionerPaymentModeExceptionsDto>(url, {
      headers,
    });
  }

  getAcctPractitionerCompensationForFixedFeesByPractitionerId(
    practitionerId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationforfixedfees/practitioner/${practitionerId}`;
    return this.http.get<AcctPractitionerCompensationForFixedFeeDto[]>(url, {
      headers,
    });
  }

  insertAcctPractitionerCompensationForFixedFees(
    form: UntypedFormGroup,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationforfixedfees`;
    return this.http.post<AcctPractitionerCompensationForFixedFeeDto>(
      url,
      createInsertBody(form),
      { headers }
    );
  }

  patchAcctPractitionerCompensationForFixedFees(
    form: UntypedFormGroup,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationforfixedfees/${
      form.get('id')?.value
    }`;
    return this.http.patch<AcctPractitionerCompensationForFixedFeeDto>(
      url,
      createPatchBody(form),
      { headers }
    );
  }

  deleteAcctPractitionerCompensationForFixedFees(id: number, clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationforfixedfees/${id}`;
    return this.http.delete<any>(url, { observe: 'response', headers });
  }

  batchAcctPractitionerCompensationForHolidays(
    compensationSetupCreation: AcctPractitionerCompensationForHolidayDto[],
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationforholidays/batch`;
    return this.http.post<AcctPractitionerCompensationForHolidayDto[]>(
      url,
      compensationSetupCreation,
      { headers }
    );
  }

  deleteAcctPractitionerCompensationForHolidays(
    id: number,
    clinicId: number
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationforholidays/${id}`;
    return this.http.delete(url, { observe: 'response', headers });
  }
  patchAcctPractitionerCompensationForHolidays(
    form: UntypedFormGroup,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}compensationforholidays/${
      form.get('id')?.value
    }`;
    return this.http.patch<AcctPractitionerCompensationForHolidayDto>(
      url,
      createPatchBody(form),
      { headers }
    );
  }
  getAcctPostingSchedules(clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}acctpostingschedules`;
    return this.http.get<AcctPostingScheduleDto[]>(url, { headers });
  }
  getCMWebUsers(clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}users`;
    return this.http.get<StaffUserDto[]>(url, { headers });
  }

  insertAcctPostingSchedule(form: UntypedFormGroup, clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}acctpostingschedules`;
    return this.http.post<AcctPostingScheduleDto>(url, createInsertBody(form), {
      headers,
    });
  }

  deleteAcctPostingSchedule(id: number, clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}acctpostingschedules/${id}`;
    return this.http.delete(url, { observe: 'response', headers });
  }

  getSysBillingPostingOptions() {
    const url = `${this.coApiUrl}sysbillingpostingoptions`;
    return this.http.get<SysBillingPostingOptionDto[]>(url);
  }

  // getAcctClinicNonDefaultTaxSetups() {
  //   const url = `${this.addatechApiUrl}acctclinicnondefaulttaxsetups`;
  //   return this.http.get<AcctClinicNonDefaultTaxSetupDto[]>(url);
  // }

  getTaxSetupsWithActiveRate() {
    const url = `${this.coApiUrl}accttaxsetups/dropdownlist`;
    return this.http.get<AcctTaxSetupDto[]>(url);
  }

  getAcctClinicSetup(clinicId: number) {
    const url = `${this.coApiUrl}acctclinicsetups`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.get<AcctClinicSetupDto>(url, {
      headers,
    });
  }
  getCoServicesDropdown() {
    const url = `${this.coApiUrl}centraloffices/services/co/dropdownlist`;
    return this.http.get<ServiceDto[]>(url);
  }

  getSysAppointmentCancellationBillingOptions() {
    const url = `${this.coApiUrl}sysappointmentcancellationbillingoptions`;
    return this.http.get<SysAppointmentCancellationBillingOptionDto[]>(url);
  }

  getAppointmentCancellationRateToBillException(
    sysAppointmentCancellationTypeId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}appointmentcancellationratetobillexceptions?sysAppointmentCancellationTypeId=${sysAppointmentCancellationTypeId}`;
    return this.http.get<AppointmentCancellationRateToBillExceptionDto[]>(url, {
      headers,
    });
  }

  getInvoicePrefix() {
    const url = `${this.coApiUrl}invoiceprefixes`;
    return this.http.get<InvoicePrefixDto[]>(url);
  }

  getSysInvoiceStyles() {
    const url = `${this.coApiUrl}sysinvoicestyles`;
    return this.http.get<SysInvoiceStyleDto[]>(url);
  }
  getClinicAcctInvoiceStyles(clinicId: number) {
    const url = `${this.coApiUrl}acctinvoicestyles/clinics`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.get<AcctInvoiceStyleDto[]>(url, {
      headers,
    });
  }

  postAppointmentCancellationRateToBillException(
    appCancellationRateToBillExceptions: AppointmentCancellationRateToBillExceptionDto[],
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}appointmentcancellationratetobillexceptions`;
    return this.http.post<AppointmentCancellationRateToBillExceptionDto[]>(
      url,
      appCancellationRateToBillExceptions,
      { headers }
    );
  }

  deleteAppointmentCancellationRateToBillException(
    serviceId: number,
    sysAppointmentCancellationTypeId: number,
    clinicId: number
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}appointmentcancellationratetobillexceptions/services/${serviceId}/sysappointmentcancellationtypes/${sysAppointmentCancellationTypeId}`;
    return this.http.delete(url, { observe: 'response', headers });
  }

  patchAppointmentCancellationRateToBillException(
    form: UntypedFormGroup,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${
      this.coApiUrl
    }appointmentcancellationratetobillexceptions/services/${
      form.get('serviceId')?.value
    }/sysappointmentcancellationtypes/${
      form.get('sysAppointmentCancellationTypeId')?.value
    }`;
    return this.http.patch<AppointmentCancellationRateToBillExceptionDto>(
      url,
      createPatchBody(form),
      { headers }
    );
  }
  insertAcctClinicSetup(form: FormGroup, clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}acctclinicsetups`;
    return this.http.post<AcctClinicSetupDto>(url, createInsertBody(form), {
      headers,
    });
  }
  getStaffTeams(): Observable<StaffTeamDto[]> {
    const url = `${this.coApiUrl}staffteams`;
    return this.http.get<StaffTeamDto[]>(url);
  }
  getCoClinicStaffTeamMembers(
    staffTeamId: number
  ): Observable<CoStaffTeamMemberByClinicDto[]> {
    const url = `${this.coApiUrl}centraloffices/staffteams/${staffTeamId}/members`;
    return this.http.get<CoStaffTeamMemberByClinicDto[]>(url);
  }
  getStaffTeam(
    staffTeamId: number,
    clinicId: number
  ): Observable<StaffTeamDto> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}staffteams/${staffTeamId}`;
    return this.http.get<StaffTeamDto>(url, { headers });
  }
  createStaffTeam(form: UntypedFormGroup): Observable<StaffTeamDto> {
    const url = `${this.coApiUrl}staffteams/`;
    return this.http.post<StaffTeamDto>(url, createInsertBody(form));
  }
  updateStaffTeam(
    staffTeamId: number,
    form: UntypedFormGroup
  ): Observable<StaffTeamDto> {
    const url = `${this.coApiUrl}staffteams/${staffTeamId}`;
    return this.http.put<StaffTeamDto>(url, createUpdateBody(form));
  }
  updateClinicStaffTeamMembers(
    clinicId: number,
    staffTeamId: number,
    staffEntityIds: number[]
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicstaffteammembers/${staffTeamId}`;

    return this.http.put<any>(url, { staffEntityIds }, { headers });
  }
  deleteStaffTeam(id: number): Observable<any> {
    const url = `${this.coApiUrl}centraloffices/staffteams/${id}`;
    return this.http.delete<any>(url);
  }
  getActivePractitionerAdminCMWebUsersDropdownlist(
    includeInactive: boolean = false,
    clinicId: number
  ): Observable<UserDropDownListDto[]> {
    const url = `${this.coApiUrl}users/dropdownlist?includeInactive=${includeInactive}`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.get<UserDropDownListDto[]>(url, { headers });
  }

  getSysChartNoteHeaderFields(): Observable<SysChartNoteHeaderFieldDto[]> {
    const url = `${this.coApiUrl}syschartnoteheaderfields`;
    return this.http.get<SysChartNoteHeaderFieldDto[]>(url);
  }
  getSysDocumentTypes(): Observable<SysDocumentTypeDto[]> {
    const url = `${this.coApiUrl}sysdocumenttypes`;
    return this.http.get<SysDocumentTypeDto[]>(url);
  }
  getClinicChartNoteHeaderDocumentTypeSetup(
    clinicId: number
  ): Observable<ClinicChartNoteHeaderDocumentTypeSetupDto[]> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicchartnoteheaderdocumenttypesetups`;
    return this.http.get<ClinicChartNoteHeaderDocumentTypeSetupDto[]>(url, {
      headers,
    });
  }
  batchClinicChartNoteHeaderSetups(
    clinicId: number,
    headerIds: number[]
  ): Observable<ClinicChartNoteHeaderSetupDto[]> {
    const url = `${this.coApiUrl}clinicchartnoteheadersetups`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.put<ClinicChartNoteHeaderSetupDto[]>(url, headerIds, {
      headers,
    });
  }
  batchClinicChartNoteHeaderDocumentTypeSetup(
    clinicId: number,
    documentIds: number[]
  ): Observable<ClinicChartNoteHeaderDocumentTypeSetupDto[]> {
    const url = `${this.coApiUrl}clinicchartnoteheaderdocumenttypesetups`;
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    return this.http.put<ClinicChartNoteHeaderDocumentTypeSetupDto[]>(
      url,
      documentIds,
      { headers }
    );
  }
  getClinicChartNoteHeaderSetups(
    clinicId: number
  ): Observable<ClinicChartNoteHeaderSetupDto[]> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinicchartnoteheadersetups`;
    return this.http.get<ClinicChartNoteHeaderSetupDto[]>(url, { headers });
  }
  getCoClinicsWithPractitionerLicenceCountValidationDto(): Observable<
    CoClinicWithPractitionerLicenseCountValidationDto[]
  > {
    const url = `${this.coApiUrl}centraloffices/clinics/practitionerlicencevalidation`;
    return this.http.get<CoClinicWithPractitionerLicenseCountValidationDto[]>(
      url
    );
  }

  patchClinicOnlineBookingSetup(form: UntypedFormGroup, clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinics/onlinebookingsetup`;
    return this.http.patch<ClinicOnlineBookingSetupDto>(
      url,
      createPatchBody(form),
      {
        headers,
      }
    );
  }

  getSecuredMessageCategories() {
    const url = `${this.coApiUrl}securedmessagecategories`;
    return this.http.get<SecuredMessageCategoryDto[]>(url);
  }

  getClinicOnlineBookingSetup(clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}clinics/onlinebookingsetup`;
    return this.http.get<ClinicOnlineBookingSetupDto>(url, { headers });
  }

  getSecuremessageDefaultRecipientTeams(clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}securemessagedefaultrecipientteams`;
    return this.http.get<SecuredMessageDefaultRecipientDto[]>(url, { headers });
  }

  insertSecuredMessageCategories(form: UntypedFormGroup, clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}securedmessagecategories`;
    return this.http.post<SecuredMessageCategoryDto>(
      url,
      createInsertBody(form),
      { headers }
    );
  }

  patchSecuredMessageCategories(
    form: UntypedFormGroup,
    refId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}securedmessagecategories/${refId}`;
    return this.http.patch<SecuredMessageCategoryDto>(
      url,
      createPatchBody(form),
      { headers }
    );
  }

  insertSecuremessageDefaultRecipientTeams(
    recipients: SecuredMessageDefaultRecipientDto[],
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}securemessagedefaultrecipientteams`;
    return this.http.post<SecuredMessageDefaultRecipientDto[]>(
      url,
      recipients,
      { headers }
    );
  }

  deleteSecuredMessageCategories(id: number) {
    const url = `${this.coApiUrl}SecuredMessageCategories/${id}`;
    return this.http.delete(url, { observe: 'response' });
  }

  deleteSecureMessageDefaultRecipientTeams(id: number): Observable<any> {
    const url = `${this.coApiUrl}securemessagedefaultrecipientteams/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  getFromModuleClinics(sysApplicationModule: SysApplicationModuleEnum) {
    const url = `${this.coApiUrl}centraloffices/clinics/frommodule/${sysApplicationModule}`;
    return this.http.get<ClinicDto[]>(url);
  }
  getClinicGroupFranchiseOnlineBookingSetup() {
    const url = `${this.fileApiUrl}clinicgroupfranchisesonlinebookingsetup`;
    return this.http.get<ClinicGroupFranchiseOnlineBookingSetupDto>(url);
  }
  insertClinicGroupFranchisesOnlinebookingSetup(
    form: UntypedFormGroup
  ): Observable<ClinicGroupFranchiseOnlineBookingSetupDto> {
    const headers = new HttpHeaders({ 'Content-Type': '' });
    const url = `${this.fileApiUrl}clinicgroupfranchisesonlinebookingsetup`;
    return this.http.post<ClinicGroupFranchiseOnlineBookingSetupDto>(
      url,
      createPutFormData(form),
      {
        headers,
      }
    );
  }

  updateClinicGroupFranchisesOnlinebookingSetup(
    form: UntypedFormGroup
  ): Observable<ClinicGroupFranchiseOnlineBookingSetupDto> {
    const headers = new HttpHeaders({ 'Content-Type': '' });
    const url = `${this.fileApiUrl}clinicgroupfranchisesonlinebookingsetup`;
    return this.http.put<ClinicGroupFranchiseOnlineBookingSetupDto>(
      url,
      createPutFormData(form),
      {
        headers,
      }
    );
  }
  getCoAcctSetup() {
    const url = `${this.coApiUrl}centraloffices/clinicapplicationsetups/coacctsetup`;
    return this.http.get<CoAcctSetupDto>(url);
  }
  saveCoAcctSetup(form: UntypedFormGroup): Observable<CoAcctSetupDto> {
    const url = `${this.coApiUrl}centraloffices/clinicapplicationsetups/coacctsetup`;
    return this.http.post<CoAcctSetupDto>(url, createInsertBody(form));
  }

  //PDF form templates
  getAllSmartDocTemplates(clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}chartnotesmartdocs/setup`;
    return this.http.get<ChartNoteTemplateSmartDocDto[]>(url, { headers });
  }

  getSysSmartDocCategories() {
    const url = `${this.coApiUrl}syschartnotesmartdoccategories`;
    return this.http.get<SysChartNoteSmartDocCategoryDto[]>(url);
  }

  getUnassociatedSmartDocTemplates(clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}syschartnotesmartdoctemplate/unassociated`;
    return this.http.get<SysChartNoteSmartDocTemplateDto[]>(url, { headers });
  }

  getFundersDropdownPassIntegration(
    integrationTypes: CaseIntegrationType[],
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}funders/dropdownlist`;
    return this.http
      .get<FunderDropdownItemModel[]>(url, { headers })
      .pipe(map((body) => (body ? body : [])))
      .pipe(
        map((fundersDs) => {
          const apiFunders = [...(fundersDs || [])];
          apiFunders.map((f) => {
            // f.iconUrl = this.getIntegrationIcons(f.caseIntegrationType, integrationTypes);
            f.iconUrl = integrationTypes?.find(
              (t) =>
                t.caseIntegrationTypeId ===
                f.caseIntegrationType?.caseIntegrationTypeId
            )?.iconFileUri;
            return f;
          });
          return apiFunders;
        })
      );
  }

  getIntegrationIcons(
    integrationTypes: CaseIntegrationTypeItem[] | undefined,
    caseIntegrationTypes: CaseIntegrationType[]
  ): string[] | undefined {
    if (integrationTypes && integrationTypes?.length > 0) {
      const intTypeIds =
        integrationTypes?.map((intType) => intType.caseIntegrationTypeId) || [];
      return intTypeIds?.length > 0
        ? caseIntegrationTypes
            ?.filter(
              (intType) =>
                intTypeIds.includes(intType.caseIntegrationTypeId) &&
                !!intType.iconFileUri
            )
            ?.map((t) => t.iconFileUri) || undefined
        : undefined;
    } else {
      return undefined;
    }
  }

  getPractitionersDropdownPassIntegration(
    integrationTypes: CaseIntegrationType[],
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}practitioners/dropdownlist/all`;
    return this.http
      .get<PractitionerDropdownItem[]>(url, { headers })
      .pipe(map((body) => (body ? body : [])))
      .pipe(
        map((apiPractitioners) => {
          const prDropdown = [...(apiPractitioners || [])];
          prDropdown.map((pr) => {
            pr.disabled = !pr.isActive;
            pr.practitionerIntegrationTypesIconUrls = this.getIntegrationIcons(
              pr.caseIntegrationTypes,
              integrationTypes
            );
            return pr;
          });
          prDropdown.sort((a, b) => {
            const first = a.name.normalize('NFD').toLowerCase();
            const second = b.name.normalize('NFD').toLowerCase();
            return first > second ? 1 : second > first ? -1 : 0;
          });
          return prDropdown;
        })
      );
  }

  insertChartNoteTemplateSmartDoc(form: UntypedFormGroup, clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}chartnotetemplatesmartdocs`;
    return this.http.post<ChartNoteTemplateSmartDocDto>(
      url,
      createInsertBody(form),
      { headers }
    );
  }

  patchChartNoteTemplateSmartDoc(form: UntypedFormGroup, clinicId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}chartnotetemplatesmartdocs/${
      form.get('chartNoteTemplateSmartDocId')?.value
    }`;
    return this.http.patch<ChartNoteTemplateSmartDocDto>(
      url,
      createPatchBody(form),
      { headers }
    );
  }

  deleteChartNoteTemplateSmartDoc(
    id: number,
    clinicId: number
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}chartnotetemplatesmartdocs/${id}`;
    return this.http.delete<any>(url, { observe: 'response', headers });
  }

  getChartNoteSmartDocBillableItemsOnSignedOff(
    templateId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}chartnotetemplatesmartdocbillableitemsonsignedoffs/bytemplate/${templateId}`;
    return this.http.get<ChartNoteSmartDocBillableItemsOnSignedOffDto[]>(url, {
      headers,
    });
  }

  insertChartNoteSmartDocBillableItemsOnSignedOff(
    form: UntypedFormGroup,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}chartnotetemplatesmartdocbillableitemsonsignedoffs`;
    return this.http.post<ChartNoteSmartDocBillableItemsOnSignedOffDto>(
      url,
      createInsertBody(form),
      { headers }
    );
  }

  patchChartNoteSmartDocBillableItemsOnSignedOff(
    form: UntypedFormGroup,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${
      this.coApiUrl
    }chartnotetemplatesmartdocbillableitemsonsignedoffs/${
      form.get('id')?.value
    }`;
    return this.http.patch<ChartNoteSmartDocBillableItemsOnSignedOffDto>(
      url,
      createPatchBody(form),
      { headers }
    );
  }

  deleteChartNoteSmartDocBillableItemsOnSignedOff(
    id: number,
    clinicId: number
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}chartnotetemplatesmartdocbillableitemsonsignedoffs/${id}`;
    return this.http.delete<any>(url, { observe: 'response', headers });
  }

  getSmartDocEntitiesWithFormMappings(
    templateFormId: number,
    clinicId: number
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}chartnotetemplatesmartdoc/${templateFormId}/entities`;
    return this.http.get<ChartNoteTemplateSmartDocEntityMappingDto[]>(url, {
      headers,
    });
  }

  postSmartDocEntityFormMappings(
    templateFormId: number,
    maps: ChartNoteTemplateSmartDocEntityMappingDto[],
    clinicId: number
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}chartnotetemplatesmartdoc/${templateFormId}/entities`;
    return this.http.post<any>(
      url,
      (() => {
        const bodyArray = [];

        for (let i = 0; i < maps.length; i++) {
          bodyArray.push(maps[i].entityId);
        }

        return `{"assignedEntityIds": ${JSON.stringify(bodyArray)}}`;
      })(),
      { observe: 'response', headers }
    );
  }

  postMassSmartDocEntityFormMappings(
    templateFormIds: number[],
    maps: ChartNoteTemplateSmartDocEntityMappingDto[],
    clinicId: number
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}chartnotetemplatesmartdoc/entities/batchassign`;
    return this.http.post<any>(
      url,
      (() => {
        const bodyArray = [];

        for (let i = 0; i < maps.length; i++) {
          bodyArray.push(maps[i].entityId);
        }
        return `{"assignedEntityIds": ${JSON.stringify(
          bodyArray
        )}, "chartNoteTemplateSmartDocIds": ${JSON.stringify(
          templateFormIds
        )}}`;
      })(),
      { observe: 'response', headers }
    );
  }

  putMassSubCategoryForChartNoteSmartDocs(
    templateFormIds: number[],
    subCategoryId: number,
    clinicId: number
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('clinicId', clinicId.toString());
    const url = `${this.coApiUrl}chartnotetemplatesmartdocs/${subCategoryId}/assignedSubCategory`;
    return this.http.put<any>(
      url,
      (() => {
        return `{"ChartNoteTemplateSmartDocIds": ${JSON.stringify(
          templateFormIds
        )}}`;
      })(),
      { observe: 'response', headers }
    );
  }
  //#region GP Posting
  getClinicsForGPPostings() {
    const url = `${this.coApiUrl}centraloffice/acctpostingschedules/gp/clinicsforpostings`;
    return this.http.get<CoPostingDateAndClinicsDto>(url);
  }
  getClinicAcctGpPostingSchedules(clinicId: number) {
    const url = `${this.coApiUrl}centraloffice/acctpostingschedules/gp/coclinicfilteredpostings/${clinicId}`;
    return this.http.get<COClinicWithGpPostingDetailsDto[]>(url);
  }
  public cbiExtractGreatPlainsMonthlyReport(
    param: GreatPlainsMontlyReportParameters
  ) {
    const url = `${this.coApiUrl}centraloffice/acctpostingschedules/cbiextractppmontlyreport`;
    return this.http.post<boolean>(url, param, {
      headers: {
        'Skip-Error': '',
      },
    });
  }
  //#endregion
}
