import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from '../layout/services/loader.service';
import { DynamicLoaderService } from '../dynamic/services/dynamic-loader.service';
import { ModalConfiguration } from '../dynamic/models/modal.model';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { InitialStartupService } from '../../auth/initial-startup.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private authService: InitialStartupService,
    private dynamicLoaderService: DynamicLoaderService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.show();
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const skipErrorDialog = req.headers.has('Skip-Error');
        if (skipErrorDialog) {
          return throwError(() => errorMessage);
        }
        let errorMessage = '';
        let showCopy = false;
        let errorCode = undefined;
        let isWarning = false;
        let isPopup = false;
        const applicationError = error.headers.get('Application-Error');

        if (applicationError) {
          errorMessage = applicationError;
        } else if (error.status === 400 || error.status === 500) {
          errorCode = error.status;
          errorMessage = error.error;
          showCopy = true;
          isPopup = true;
        } else if (error.status === 401) {
          errorCode = error.status;
          errorMessage = this.translateService.instant('login.unauthorized');
          isWarning = true;
          isPopup = true;
          this.authService.logout();
        } else if (error.status === 404) {
          errorCode = error.status;
          errorMessage = error.error;
          isWarning = true;
          isPopup = true;
        } else if (error.status === 422) {
          errorCode = error.status;
          if (typeof error.error === 'object') {
            Object.keys(error.error).forEach((key, id, array) => {
              if (id === array.length - 1) {
                errorMessage += `${error.error[key].toString()}`;
              } else {
                errorMessage += `${error.error[key].toString()}<br><br>`;
              }
            });
          } else {
            errorMessage = error.error;
          }
          isWarning = false;
          isPopup = true;
        } else if (error.status === 403) {
          errorCode = error.status;
          errorMessage = this.translateService.instant('login.unauthorized');
          isWarning = true;
          isPopup = true;
        } else {
          errorCode = error.status;
          errorMessage = error.error;
          isWarning = true;
          isPopup = true;
        }
        if (isPopup) {
          const configuration: ModalConfiguration = {
            panelClass: 'popup small',
            animation: 'scale',
            outsideClick: false,
          };
          const data = {
            code: errorCode,
            message: errorMessage,
            showCopy: showCopy,
            isWarning: isWarning,
          };
          this.dynamicLoaderService
            .open<ErrorDialogComponent>(
              ErrorDialogComponent,
              configuration,
              data
            )
            .then((container) => {
              return container.componentRef?.instance.closeClick.subscribe(
                () => {
                  isPopup = false;
                  container.close();
                }
              );
            });
        }
        return throwError(() => errorMessage);
      }),
      finalize(() => {
        this.loaderService.hide();
      })
    );
  }
}

export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
